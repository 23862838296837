import React, { Component } from 'react'
import Login from './scenes/Login'
import Main from './scenes/Main'
import ErrorPage from './scenes/Error'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    console.log('getDerivedStateFromError')
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log('caught error')
    console.log('error', error)
    console.log('errorInfo', errorInfo)
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorPage />
      )
    }

    return this.props.children
  }
}

class App extends Component {
  constructor (props) {
    super(props)

    this.state = {
      authToken: window.localStorage.getItem('auth-token'),
      currentAdmin: window.localStorage.getItem('currentAdmin')
    }

    this._login = this._login.bind(this)
    this._logout = this._logout.bind(this)
  }

  render () {
    const { authToken } = this.state

    // For some reason currentAdmin state is converted to string undefined...
    // This is just temp code to ensure that things work even
    // without changes from API
    const currentAdmin =
      this.state.currentAdmin && this.state.currentAdmin !== 'undefined'
        ? JSON.parse(this.state.currentAdmin)
        : null

    if (!authToken) {
      return (<Login login={this._login} />)
    }

    return (
      <ErrorBoundary>
        <Main
          authToken={authToken}
          currentAdmin={currentAdmin}
          logout={this._logout}
        />
      </ErrorBoundary>
    )
  }

  _logout () {
    window.localStorage.removeItem('auth-token')
    window.localStorage.removeItem('currentAdmin')
    this.setState({ authToken: null, currentAdmin: null })
    window.location = '/'
  }

  _login (token, admin) {
    this.setState({ authToken: token, currentAdmin: admin })
  }
}

export default App
