import React, { useState, useEffect } from 'react'
import PromotionsAPI from 'services/api/promotions'
import { Redirect } from 'react-router-dom'
import {
  viewPromotionUrl,
  Breadcrumbs
} from './components'
import {
  Form,
  FormLine,
  DateInput,
  Submit
} from 'components/Forms'

const EditSalesPeriod = ({ match }) => {
  const { promotionID: id, operatorID: operatorId } = match.params
  const [promotion, setPromotion] = useState(null)
  const [isUpdated, setIsUpdated] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})

  useEffect(() => PromotionsAPI.get(operatorId, id, setPromotion), [id, operatorId])

  if (!promotion) return null

  const onSubmit = data => {
    PromotionsAPI.update(
      { type: promotion.promotion_type, part: 'sales_period', id, ...data },
      _successfulResult => setIsUpdated(true),
      errorResult => setValidationErrors(errorResult)
    )
  }

  if (isUpdated) return <Redirect to={viewPromotionUrl(id, operatorId)} />

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <Breadcrumbs operatorId={operatorId} promotion={promotion} />
        Edit sales period
      </h1>
      <div className='content-box form'>
        <Form onSubmit={onSubmit} defaultValues={promotion} validationErrors={validationErrors}>
          <FormLine>
            <DateInput name='sales_period_begins_at' label='Begin date' />
            <DateInput name='sales_period_ends_at' label='End date' />
          </FormLine>
          <Submit label='Update' />
        </Form>
      </div>
    </div>
  )
}

export default EditSalesPeriod
