import React from 'react'
import Select from 'react-select'

const Export = ({ exportToExcel }) => {
  const options = [
    { label: 'Excel', value: 'excel' }
  ]

  return (
    <div>
      <h3>Export</h3>
      <Select
        options={options}
        value='Select'
        onChange={exportToExcel}
      />
    </div>
  )
}

export default Export
