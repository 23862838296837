import React from 'react'
import Icon, { GradientLock } from 'components/Icons'

const ErrorPage = ({ error, history }) =>
  <div className='no-access-wrapper'>
    <ErrorComponent error={error} history={history} />
  </div>

const ErrorComponent = ({ error, history }) =>
  <div className='no-access'>
    <div className='content-box'>
      <GradientLock />
      <h1>Something went wrong</h1>
      <p>We are sorry, but something went wrong here..</p>
      <p>{JSON.stringify(error)}</p>
      <button className='btn' style={{ minWidth: '124px', display: history ? 'inline' : 'none' }} onClick={history ? history.goBack : null}>
        <Icon
          type='arrow'
          direction='left'
          color='white'
          style={{
            fontSize: '20px',
            marginBottom: '-5px'
          }}
        /> Go back
      </button>
    </div>
  </div>

export default ErrorPage

export {
  ErrorComponent,
  ErrorPage
}
