import React from 'react'

import Select from 'react-select'

const ReportPeriodSelect = ({ onChange }) => {
  const options = [
    { value: 'lastTwelveMonths', label: 'Last 12 months' },
    { value: 'currentYear', label: 'Current year' },
    { value: 'lifetime', label: 'Lifetime' }
  ]

  return (
    <div className='report-period-select'>
      <Select
        className='react-select'
        classNamePrefix='react-select'
        options={options}
        defaultValue={options[0]}
        onChange={onChange}
        isSearchable={false}
        hideSelectedOptions
      />
    </div>
  )
}

export default ReportPeriodSelect
