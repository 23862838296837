/* global Blob */

import ExcelJS from 'exceljs/modern.browser'
import { saveAs } from 'file-saver'

/*
 * Generates Excel file using the exceljs lib.
 *
 * Currently the raw meta data supplied with the Clickhouse query result
 * is passed in ( Array[Object{name,type}], but only used to extract column names.
 *
 * TODO: Convert cell values to their proper data type using metadata (if it makes
 * sense from a user perspective)
 */
const toExcel = ({ meta, data }) => {
  return () => {
    if (data.length > 0) {
      const workbook = new ExcelJS.Workbook()
      const sheet = workbook.addWorksheet('export')

      sheet.columns = meta.map(col => {
        return {
          header: col.name,
          key: col.name,
          width: 20
        }
      })

      data.forEach(row => {
        sheet.addRow(row)
      })

      workbook.xlsx
        .writeBuffer()
        .then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/vnd.ms-excel;charset=utf-8' }), 'export.xls')
        })
    }
  }
}

export default toExcel
