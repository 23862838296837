import React from 'react'
import { FilterButton } from 'components/Buttons'
import { ColumnSelector } from 'components/Table'
import Icon from 'components/Icons'

const GuideLink = ({ visible, onClick }) => {
  if (!visible) return null

  return (
    <div style={{ cursor: 'pointer' }} onClick={onClick}>
      <Icon type='info' color='black' />
      &nbsp;<span>Filter guide</span>&nbsp;
    </div>
  )
}

const GeneralFilterGuide = ({ visible, onClick }) => {
  if (!visible) return null

  const style = {
    backgroundColor: '#4727AA',
    color: 'white',
    padding: '10px',
    display: 'grid',
    gridTemplateColumns: '30px 1fr 30px',
    gridColumnGap: '2px'
  }

  return (
    <div style={style}>
      <div style={{ fontSize: '1.2em' }}>
        <Icon type='info' color='white' />
      </div>
      <div>
        <strong>General filter guide</strong>
        <ul>
          <li>In the "Select columns" field you can select which columns to display in the table bellow.</li>
          <li>For each column you can search for records matching the value (search field between column header and first record). Some fields might have a selector instead of a search field.</li>
          <li>You can sort each column by clicking the header. Click it again to sort the other directions.</li>
        </ul>
      </div>
      <div style={{ fontSize: '1.2em', cursor: 'pointer' }} onClick={onClick}>
        X
      </div>
    </div>
  )
}

const FilterGuide = () => {
  const [info, setInfo] = React.useState(false)

  const style = { marginTop: '15px' }
  const toggle = () => setInfo(!info)

  return (
    <div style={style}>
      <GuideLink visible={!info} onClick={toggle} />
      <GeneralFilterGuide visible={info} onClick={toggle} />
    </div>
  )
}

const ColumnSelectorWrapper = ({ withColumns = true, columns = [], setColumns }) => {
  if (!withColumns) return null

  return (
    <ColumnSelector
      columns={columns}
      setColumns={setColumns}
    />
  )
}

const Search = ({ withSearch = false, onSubmit, onChange }) => {
  return (
    <div className='Search' style={{ visibility: withSearch ? 'visible' : 'hidden' }}>
      <form onSubmit={onSubmit}>
        <input
          className='listSearch'
          type='text'
          placeholder='Search'
          onChange={onChange}
        />
      </form>
    </div>
  )
}

// This is the filters section above the table
// It provides 2 optional components
//  - search input (provide withSearch={true} - default false
//  - column selector (default true, but can be omitted by withColumns={false})
const Filters = ({ setColumns, columns = [], onSubmit, onChange, withSearch, children, withColumns, toggleFiltered }) => {
  const [active, setActive] = React.useState(false)

  const toggle = (value) => {
    if (toggleFiltered) toggleFiltered(value)
    setActive(value)
  }

  return (
    <div className='Filters content-box no-background'>
      <Search
        onSubmit={onSubmit}
        onChange={onChange}
        withSearch={withSearch}
      />
      <FilterButton
        setActive={toggle}
        active={active}
      />
      <div className='break' />
      <div className='filter-by' style={{ display: active ? 'block' : 'none' }}>
        <ColumnSelectorWrapper
          columns={columns}
          setColumns={setColumns}
          withColumns={withColumns}
        />
        {children}
        <FilterGuide />
      </div>
    </div>
  )
}

export default Filters
