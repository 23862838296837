import React, { useRef, useEffect } from 'react'

import humanize from './humanize'
import ErrorMessage from './ErrorMessage'
import InfoMessage from './InfoMessage'
import { useFormContext } from 'react-hook-form'

const TextInput = ({ label, name, required = false, size = null, info, focus = false, ...rest }) => {
  const inputRef = useRef(null)
  useEffect(() => {
    if (focus) {
      inputRef.current.focus()
    }
  }, [inputRef, focus])

  const { register, errors } = useFormContext()

  return (
    <label>{label || humanize(name)}
      <input
        type='text'
        className={`
          ${size} ${errors[name] ? 'error' : ''}
        `}
        name={name}
        ref={(e) => {
          register(e, { required: required })
          inputRef.current = e
        }}
        {...rest}
      />
      <ErrorMessage name={name} errors={errors} />
      <InfoMessage message={info} />
    </label>
  )
}

export default TextInput
