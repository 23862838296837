import React from 'react'
import Amount from 'components/Helpers/Amount'
import Period from './Period'
import { timeToLocale } from 'components/Helpers/dateToLocale'
import { countDeclinedPayments } from './Helpers'

const successfullyChargedAt = (invoice, timezone) => {
  const accepted = invoice.payments.find(payment => payment.status === 'accepted')

  if (!accepted) return '?'

  return timeToLocale(accepted.created_at, timezone)
}

const UserMessage = ({ invoice, timezone }) => {
  let msg
  if (invoice.status === 'charged') {
    msg = `Charged at ${successfullyChargedAt(invoice, timezone)}`
  } else {
    msg = `Declined ${countDeclinedPayments(invoice)} time(s)`
  }

  return (
    <div>
      <h2>Invoice {invoice.oid}</h2>
      <Period period={invoice.period} />
      &nbsp;&sdot;&nbsp;
      <span className='amount'>
        <Amount amount={invoice.amount} currency={invoice.currency} />
      </span>
      &nbsp;&sdot;&nbsp;
      <span className='message'>
        {msg}
      </span>
    </div>
  )
}

export default UserMessage
