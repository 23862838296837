const API = require('./api')

const fetchInvoices = (operatorID, userID, params, success) => {
  const url = '/memberships/' + userID + '/invoices'
  API.fetch(url, success, params)
}

const fetchScheduledInvoices = (operatorID, userID, params, success) => {
  const url = 'memberships/' + userID + '/invoices/scheduled'
  API.fetch(url, success, params)
}

const fetchInvoice = (operatorID, userID, invoiceID, success) => {
  const url = 'memberships/' + userID + '/invoices/' + invoiceID
  API.fetch(url, success)
}

const refundInvoice = (args, userID, invoiceID, success, error) => {
  const url = process.env.REACT_APP_SERVER + '/memberships/' + userID + '/invoices/' + invoiceID + '/refund'
  API.post(url, success, args, error)
}

const stopInvoice = (args, userID, invoiceID, target, success, error) => {
  const url = process.env.REACT_APP_SERVER + '/memberships/' + userID + '/invoices/' + invoiceID + '/' + target
  API.post(url, success, args, error)
}

const markInvoicePaid = (args, userID, invoiceID, success, error) => {
  const url = process.env.REACT_APP_SERVER + '/memberships/' + userID + '/invoices/' + invoiceID + '/markpaid'
  API.post(url, success, args, error)
}

module.exports = {
  fetchInvoices,
  fetchScheduledInvoices,
  fetchInvoice,
  refundInvoice,
  stopInvoice,
  markInvoicePaid
}
