import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import FreePassAPI from 'services/api/free_passes'
import { Breadcrumbs, viewFreePassListUrl } from './components'
import { Form, TextAreaInput, Mobile, Submit } from 'components/Forms'
import AppContext from 'AppContext'

const NewFreePassShort = () => {
  const { operator: { id: operatorId } } = React.useContext(AppContext)
  const [newFreePassId, setNewFreePassId] = useState(null)
  const [validationErrors, setValidationErrors] = useState({})
  const onSubmit = data => {
    FreePassAPI.create(
      { ...data, source: 'admin-ui' },
      operatorId,
      successfulResult => setNewFreePassId(successfulResult.id),
      errorResult => setValidationErrors(errorResult)
    )
  }

  if (newFreePassId) return <Redirect to={viewFreePassListUrl(operatorId)} />

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <Breadcrumbs operatorId={operatorId} />
        New free pass
      </h1>
      <div className='content-box form'>
        <Form onSubmit={onSubmit} defaultValues={{}} validationErrors={validationErrors}>
          <Mobile focus={false} operator={operatorId} />
          <TextAreaInput name='description' />
          <Submit label='Add free pass' />
        </Form>
      </div>
    </div>
  )
}

export default NewFreePassShort
