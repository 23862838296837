import { matchPath } from 'react-router'

// Issue: this.props.match.params does not resolve place holders in nested
// routes.
//
// As a workaround we're using the same lib, react-router-v4, to do the job
// one more time to get hold of the invoiceID declared in the nested route.
//
// Ref: https://stackoverflow.com/questions/52684017/react-router-v4-nested-match-params-not-accessible-at-root-level
const extractInvoiceIDFromURL = (location) => {
  const match = matchPath(location.pathname, {
    path: '/operators/:operatorID/members/:membershipID/invoices/:invoiceID',
    exact: false,
    strict: false
  })

  return match.params.invoiceID
}

export default extractInvoiceIDFromURL
