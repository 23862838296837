import React from 'react'

const IconFilter = props => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <path
        id='a'
        d='M19 9c1.654 0 3-1.346 3-3s-1.346-3-3-3a2.993 2.993 0 00-2.815 2H2v2h14.185A2.993 2.993 0 0019 9zm0-4a1 1 0 110 2 1 1 0 010-2zM9 9c1.302 0 2.401.838 2.815 2H22v2H11.815A2.994 2.994 0 019 15a2.994 2.994 0 01-2.815-2H2v-2h4.185A2.993 2.993 0 019 9zm0 4a1 1 0 100-2 1 1 0 000 2zm10 2c1.654 0 3 1.346 3 3s-1.346 3-3 3a2.994 2.994 0 01-2.815-2H2v-2h14.185A2.993 2.993 0 0119 15zm0 4a1 1 0 100-2 1 1 0 000 2z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <mask id='b' fill='#fff'>
        <use xlinkHref='#a' />
      </mask>
      <g fill={props.color || '#0F003F'} mask='url(#b)'>
        <path d='M0 0h24v24H0z' />
      </g>
    </g>
  </svg>
)

export default IconFilter
