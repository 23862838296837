import React, { Component } from 'react'

import { ActionButton } from 'components/Buttons/index'

import RefundModal from '../RefundModal'

class RefundButton extends Component {
  constructor (props) {
    super(props)

    this.state = {
      refundModalIsOpen: false
    }

    this.openRefundModal = this.openRefundModal.bind(this)
    this.closeRefundModal = this.closeRefundModal.bind(this)
  }

  openRefundModal () {
    this.setState({ refundModalIsOpen: true })
  }

  closeRefundModal () {
    this.setState({ refundModalIsOpen: false })
  }

  render () {
    if (this.props.invoice.status !== 'charged') {
      return null
    }

    if (this.state.refundModalIsOpen) {
      return (
        <RefundModal
          invoice={this.props.invoice}
          onClose={this.closeRefundModal}
        />
      )
    } else {
      return (
        <ActionButton
          txt='Refund'
          icon='refund white'
          onClick={this.openRefundModal}
        />
      )
    }
  }
}

export default RefundButton
