import React from 'react'
import Icon, { GradientLock } from 'components/Icons'

const NoAccessPage = ({ permission, history }) =>
  <div className='no-access-wrapper'>
    <NoAccessComponent permission={permission} history={history} />
  </div>

const NoAccessComponent = ({ permission, history }) =>
  <div className='no-access'>
    <div className='content-box'>
      <GradientLock />
      <h1>Restricted access</h1>
      <p>We are sorry, but you do not have the necessary permissions. Please contact your system administrator to gain access</p>
      <p style={{ display: 'none' }}>
        Requires permission {permission}
      </p>
      <button className='btn' style={{ minWidth: '124px' }} onClick={history ? history.goBack : null}>
        <Icon
          type='arrow'
          direction='left'
          color='white'
          style={{
            fontSize: '20px',
            marginBottom: '-5px'
          }}
        /> Go back
      </button>
    </div>
  </div>

export default NoAccessPage

export {
  NoAccessComponent,
  NoAccessPage
}
