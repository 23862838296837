import React from 'react'
import { Link } from 'react-router-dom'
import { Error, Input, OperatorSelector } from './index'

const LoginForm = ({ onSubmit, onChange, error, operatorsToSelect, down, resetState }) =>
  <form onSubmit={onSubmit}>
    <h1>Sign-in Credlock</h1>
    <p>Enter your details below.</p>

    <Input name='username' onChange={onChange} />
    <Input name='password' onChange={onChange} />

    <OperatorSelector
      operators={operatorsToSelect}
      onChange={onChange}
    />

    <Error error={error} />

    <Link style={{ float: 'right' }} to='/password/reset' onClick={resetState}>
      Forgotten password?
    </Link>

    <button
      type='submit'
      className='btn'
      disabled={down}
    >
      Sign-in
    </button>
  </form>

export default LoginForm
