import React, { Component } from 'react'
import { ActionButton } from 'components/Buttons'
import MembersAPI from 'services/api/members'
import { toast } from 'react-toastify'
import CancelMembership from './CancelMembership'
// import ReactivateMembership from './ReactivateMembership'

// const StopMembership = ({ confirm, status }) => {
//   if (['cancelled','stopped','cancelled_in_trial','cancelled_in_presale'].includes(status))
//       return null

//   return (
//     <div className='box'>
//       <h2>End membership</h2>
//       <p>
//         This will instantly stop the customers
//         membership and all access to locations.
//       </p>
//       <p>
//         <strong>NOTE:</strong> It might take some time before the member is fully stopped and reflected in the UID
//       </p>
//       <ActionButton
//         className='error'
//         txt='End membership'
//         icon='close white'
//         onClick={confirm}
//       />
//     </ div>
//   )
// }

const BlockMembership = ({ member }) => {
  let env = 'staging'
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = 'production'
  }

  const config = {}
  config.apiHost = `https://memberships.${env}.credlock.net`
  config.operatorId = member.operator_id
  config.membershipId = member.id
  config.token = window.localStorage.getItem('auth-token')

  return (
    <div className='box'>
      <cl-membership-blocking
        apihost={config.apiHost}
        operatorid={config.operatorId}
        token={config.token}
        membershipid={config.membershipId}
        profile={JSON.stringify(member.customer)}
      />
    </div>
  )
}

const ReactivateMembership = ({ member }) => {
  let env = 'staging'
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = 'production'
  }

  const config = {}
  config.apiHost = `https://memberships.${env}.credlock.net`
  config.operatorId = member.operator_id
  config.membershipId = member.id
  config.membershipStatus = member.status
  config.token = window.localStorage.getItem('auth-token')

  return (
    <div className='box'>
      <cl-reactivate-membership
        apihost={config.apiHost}
        operatorid={config.operatorId}
        token={config.token}
        membershipid={config.membershipId}
        membershipstatus={config.membershipStatus}
      />
    </div>
  )
}

export default class ManageMember extends Component {
  constructor (props) {
    super(props)
    this.state = {
      member: props.member
    }

    this.confirm = confirm.bind(this)
  }

  render () {
    return (
      <div className='box-wrapper'>
        <CancelMembership membership={this.state.member} />
        <BlockMembership member={this.state.member} />
        <ReactivateMembership member={this.state.member} />
      </div>
    )
  }
}

function confirm () {
  const { id, operator_id: operatorID } = this.props.member
  if (window.confirm('Are you sure want to stop the membership?')) {
    MembersAPI.stopMember({}, operatorID, id, () => {
      toast.success('Member successfully stopped.' +
        '')
      this.props.history.push(`/operators/${operatorID}/members/${id}`)
      this.props.memberFromServer()
    }, (error) => {
      toast.error('Something went wrong')
      console.log('error', error)
    })
  }
}
