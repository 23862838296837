import React from 'react'
import { DEMO } from 'Constants'
import Icon, { TopBarLogo } from '../Icons'
import AppContext from 'AppContext'

const OperatorSelector = (props) => {
  const selectedOperator = React.useContext(AppContext).operator

  if (!Array.isArray(props.operators) || props.operators.length === 0 || !selectedOperator.id) {
    return (<div className='operator'>No operators found</div>)
  }

  return (
    <div className='operator'>
      <select
        name='operator'
        value={selectedOperator.id}
        onChange={props.methods.change_operator}
      >
        {props.operators.map((operator) => {
          return (
            <option
              value={operator.id}
              key={operator.id}
            >
              {operator.name}
            </option>
          )
        })}
      </select>
    </div>
  )
}

const UserBox = () => {
  const { currentAdmin } = React.useContext(AppContext)

  return (
    <div
      className='user-box'
    >
      {currentAdmin ? currentAdmin.username : ''}
    </div>
  )
}

const ErrorNote = ({ error }) => {
  if (!error) return null

  return (
    <div className='demo'>
      <Icon type='error' color='white' />
      &nbsp;&nbsp;We are experiencing some issues with the system
    </div>
  )
}

const DemoNote = () => (
  <div className='demo'>
    {DEMO ? 'NOTE: in demo mode with some static dummy data' : ''}
  </div>
)

const Header = (props) => (
  <header>
    <nav className='top-bar desktop'>
      <TopBarLogo className='logo' />
      <OperatorSelector
        {...props}
      />
      <DemoNote />
      <ErrorNote error={props.error} />
      <UserBox />
    </nav>
    <nav className='top-bar mobile'>
      <div className='mobile-nav' onClick={props.methods.toggle_mobile_nav}>
        <Icon type='bars' color='white' height='25px' width='25px' />
      </div>
    </nav>
  </header>
)

export default Header

export {
  OperatorSelector
}
