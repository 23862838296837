import React from 'react'
import { StatusBadge } from 'components/Badges'

const referral = (promotion) =>
  promotion.promotion_type === 'referral'

const PromotionStatus = ({ promotion }) => {
  let status = promotion.status

  if (referral(promotion)) {
    if (status === 'active') {
      status = 'current'
    }
  }

  return (
    <StatusBadge status={status} />
  )
}

export default PromotionStatus
