import React from 'react'
import Select from 'react-select'

const ExportMenu = ({ toExcel, toPNG }) => {
  const options = [
    { value: 'excel', label: 'Excel' },
    { value: 'png', label: 'PNG' }
  ]

  const onChange = option => {
    const { value: format } = option

    if (format === 'excel') toExcel()
    else if (format === 'png') toPNG()
  }

  return (
    <div className='export-dropdown'>
      <Select
        placeholder={String.fromCharCode(8942)}
        options={options}
        className='react-select'
        classNamePrefix='react-select'
        value={null}
        isSearchable={false}
        onChange={onChange}
      />
    </div>
  )
}

export default ExportMenu
