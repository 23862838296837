import React from 'react'

const PTsIcon = props => (
  <svg width='1em' height='1em' viewBox='0 0 20 20' {...props}>
    <path
      d='M6,4h9c0.3,0,0.5-0.2,0.5-0.5v-1C15.5,1.7,14.8,1,14,1h-1.8c-0.4-0.6-1-1-1.7-1S9.1,0.4,8.8,1H7C6.2,1,5.5,1.7,5.5,2.5v1
			C5.5,3.8,5.7,4,6,4z M17,13c-3,0-5.5,2.5-5.5,5.5c0,1.5,0.6,2.9,1.6,3.9c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0.2,0.5,0.5,0.8,0.6
			c0,0,0.1,0,0.1,0.1c0.3,0.2,0.5,0.3,0.8,0.5c0,0,0.1,0,0.1,0c0.3,0.1,0.6,0.2,0.9,0.3c0,0,0,0,0.1,0c0.3,0.1,0.7,0.1,1,0.1
			s0.7,0,1-0.1c0,0,0,0,0.1,0c0.3-0.1,0.6-0.2,0.9-0.3c0,0,0.1,0,0.1,0c0.3-0.1,0.6-0.3,0.8-0.5c0,0,0.1,0,0.1-0.1
			c0.3-0.2,0.5-0.4,0.8-0.6c0,0,0,0,0,0c0,0,0,0,0,0c1-1,1.6-2.4,1.6-3.9C22.5,15.5,20,13,17,13z M20.6,21.1c-0.5-0.8-1.2-1.4-2-1.8
			c0.5-0.5,0.8-1.1,0.8-1.9c0-1.4-1.1-2.5-2.5-2.5s-2.5,1.1-2.5,2.5c0,0.7,0.3,1.4,0.8,1.9c-0.8,0.4-1.5,1-2,1.8
			c-0.5-0.7-0.9-1.6-0.9-2.6c0-2.5,2-4.5,4.5-4.5s4.5,2,4.5,4.5C21.5,19.5,21.2,20.4,20.6,21.1z M10.5,18.5c0-3.6,2.9-6.5,6.5-6.5
			c0.6,0,1.2,0.1,1.9,0.3c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.4V3.5C19.5,2.7,18.8,2,18,2h-1c-0.3,0-0.5,0.2-0.5,0.5v1
			C16.5,4.3,15.8,5,15,5H6C5.2,5,4.5,4.3,4.5,3.5v-1C4.5,2.2,4.3,2,4,2H3C2.2,2,1.5,2.7,1.5,3.5v19C1.5,23.3,2.2,24,3,24h9.1
			c0,0,0,0,0,0c0.3,0,0.5-0.2,0.5-0.5c0-0.2-0.1-0.3-0.2-0.4C11.2,21.8,10.5,20.2,10.5,18.5z' id='Oval-2' fill='#0F003F'
    />
  </svg>
)

export default PTsIcon
