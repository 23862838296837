import React from 'react'
import { capitalizeFirstLetter } from '../../../components/Helpers'

const Input = ({ name, onChange }) =>
  <label>
    {capitalizeFirstLetter(name)}
    <input
      type={name === 'password' ? 'password' : 'text'}
      name={name}
      required
      onChange={onChange}
    />
  </label>

export default Input
