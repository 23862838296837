import React from 'react'

import Modal from 'components/Modal'
import { ActionButton } from 'components/Buttons'

// TODO: Move CSS out of component
const RefundModalView = ({ invoice, amountToRefund, loading, onSubmit, onClose, onChange }) => {
  if (loading) {
    return null
  }

  return (
    <Modal>
      <h1 className='modal-header'>Refund</h1>
      <div className='model-content' style={{ margin: '3em 5em' }}>
        <form>
          <div style={{ marginBottom: '2em' }}>
            <label style={{ marginBottom: 0 }}>Amount to be refunded</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                name='amountToRefund'
                type='text'
                onChange={onChange}
                value={amountToRefund}
              />
              <span>&nbsp;of</span>
              <span style={{ fontWeight: 700 }}>&nbsp;{(invoice.amount / 100).toFixed(2)}</span>
              <span>&nbsp;{invoice.currency}</span>
            </div>
          </div>
          <div style={{ marginBottom: '3em' }}>
            <label style={{ marginBottom: 0 }}>Reasons for refund</label>
            <textarea
              name='reasonToRefund'
              onChange={onChange}
              style={{ width: '100%', height: '10em' }}
              placeholder='Explanation of refund'
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ActionButton
              txt='Cancel'
              onClick={onClose}
            />
            <ActionButton
              txt={`Refund ${amountToRefund} ${invoice.currency}`}
              onClick={onSubmit}
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default RefundModalView
