import React, { Component } from 'react'
import Table from 'components/Table'
import Badge from 'components/Badges'
import { ActionButton } from 'components/Buttons'
import PromotionModal from './components/PromotionModal'
import Icon from 'components/Icons'

const NoPromotionsTxt = ({ onClick }) =>
  <div>
    <span>
      This user has no active promotion.
      Click <strong>Add promotion</strong> to assign a promotion to this membership.
    </span>
    <p>
      <ActionButton
        onClick={onClick}
        txt='Add promotion'
        icon='pluss white'
      />
    </p>
  </div>

const MemberPromotion = ({ promotion, onClick }) =>
  <div>
    <Table
      style={{ marginBottom: '20px' }}
      data={[promotion]}
      showPagination={false}
      columns={[
        {
          width: 120,
          Header: 'Type',
          accessor: 'promotion_type',
          Cell: props => {
            let type
            if (!props.value) {
              type = 'unknown'
            } else {
              type = props.value.split('_')[0]
            }
            return <Badge className='black' txt={type.toUpperCase()} />
          }
        },
        {
          Header: 'Name',
          accessor: 'name'
        },
        {
          width: 55,
          Header: '',
          Cell: () =>
            <Icon
              type='trash'
              style={{
                opacity: '0.5',
                fontSize: '17px',
                marginBottom: '-6px'
              }}
            />
        }
      ]}
    />
    <ActionButton
      onClick={onClick}
      txt='Move to promotion'
      icon='repeat white'
    />
  </div>

export default class Promotion extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal () {
    this.setState({ modalIsOpen: true })
  }

  closeModal () {
    this.setState({ modalIsOpen: false })
  }

  render () {
    const { promotion } = this.props.member

    if (this.state.modalIsOpen) {
      return (
        <PromotionModal
          operatorID={this.props.member.operator_id}
          memberID={this.props.member.id}
          promotion={this.props.member.promotion}
          closeModal={this.closeModal}
          memberFromServer={this.props.memberFromServer}
        />
      )
    } else {
      return (
        <div className='box-wrapper single'>
          <div className='box'>
            <h3>Active promotion</h3>
            {
              promotion
                ? <MemberPromotion onClick={this.openModal} promotion={promotion} />
                : <NoPromotionsTxt onClick={this.openModal} />
            }
          </div>
        </div>
      )
    }
  }
}
