import React from 'react'
import Table, {
  ExportSelector,
  Filters
} from 'components/Table'
import buildColumns from './columns'
import { LocationsSelector } from 'components/Table/components/Filters/locations'
import { StatusSelector } from './filters/status'
import { ErrorComponent } from 'scenes/Error'

const MembershipFilters = ({ filtered, setFiltered, setColumns, columns, exportToExcel, searchChange, search, setFilterable }) => {
  return (
    <Filters
      columns={columns}
      setColumns={setColumns}
      onSubmit={search}
      onChange={searchChange}
      toggleFiltered={setFilterable}
      withSearch
    >
      <div style={{
        display: 'grid',
        gridTemplateColumns: '3fr 3fr 1fr',
        gridColumnGap: '20px'
      }}
      >
        <div>
          <h3>Filter by status</h3>
          <StatusSelector
            filtered={filtered}
            onChange={setFiltered}
          />
        </div>
        <div>
          <h3>Filter by locations</h3>
          <LocationsSelector
            filtered={filtered}
            onChange={setFiltered}
          />
        </div>
        <ExportSelector exportToExcel={exportToExcel} />
      </div>
    </Filters>
  )
}

const MembershipTable = (props) => {
  const [filterable, setFilterable] = React.useState(false)

  const filtered = props.filtered
  const setFiltered = props.onFilterChange

  const [columns, setColumns] = React.useState(buildColumns(filtered, setFiltered, setFilterable, 'default'))

  // We want to rebuild the columns to ensure that we have correct
  // state for misc filters, selects, etc
  // NOTE: Adding columns as dependency breaks links to the membership..
  React.useEffect(() => {
    const newColumns = buildColumns(
      filtered, setFiltered, setFilterable, columns
    )
    if (newColumns) setColumns(newColumns)
  }, [filtered, setFilterable, setFiltered]) // eslint-disable-line

  if (props.error) {
    return (
      <ErrorComponent error={props.error} />
    )
  }

  return (
    <div>
      <MembershipFilters
        filtered={props.filtered}
        setFiltered={setFiltered}
        setColumns={setColumns}
        columns={columns}
        exportToExcel={() => props.exportToExcel(columns)}
        searchChange={props.searchChange}
        search={props.search}
        setFilterable={setFilterable}
      />
      <div className='content-box members'>
        <Table
          filterable={filterable}
          data={props.members}
          columns={columns}
          page={props.page}
          pages={props.pages}
          onPageChange={props.onPageChange}
          serverSidePagination
          defaultFiltered={filtered}
          loading={props.loading}
          onFetchData={props.onFetchData}
        />
      </div>
    </div>
  )
}

export default MembershipTable
