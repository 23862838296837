const defaultConfig = {
  mypage: {
    url: 'https://mypage-ui-de.staging.credlock.net'
  }
}

const localhostConfig = {
  mypage: {
    url: 'http://localhost:3003'
  }
}

const atConfig = {
  mypage: {
    url: 'https://me.evofitness.at'
  }
}

const norwayConfig = {
  mypage: {
    url: 'https://me.evofitness.no'
  }
}

const chConfig = {
  mypage: {
    url: 'https://me.evofitness.ch'
  }
}

const deConfig = {
  mypage: {
    url: 'https://me.evofitness.de'
  }
}

const stagingConfig = {
  '5336003e-0105-4402-809f-93bf6498af34': {
    mypage: {
      url: 'https://mypage-ui-no.staging.credlock.net'
    }
  },
  '051b413f-0245-4e84-9228-f3c7c5f8d299': {
    mypage: {
      url: 'https://mypage-ui-at.staging.credlock.net'
    }
  },
  '4de2a7cb-bd28-4111-aa28-4bbcdf60f533': {
    mypage: {
      url: 'https://mypage-ui-ch.staging.credlock.net'
    }
  },
  'c04bc7c3-903f-4804-a952-9abc0016b824': {
    mypage: {
      url: 'https://mypage-ui-de.staging.credlock.net'
    }
  }
}

const selectConfig = (operator) => {
  const hostname = window.location.hostname
  if (hostname === 'localhost') {
    return localhostConfig
  }
  if (hostname === 'admin.evofitness.at') {
    return atConfig
  }
  if (hostname === 'admin.evofitness.no') {
    return norwayConfig
  }
  if (hostname === 'admin.evofitness.ch') {
    return chConfig
  }
  if (hostname === 'admin.evofitness.de') {
    return deConfig
  }
  if (operator && stagingConfig[operator]) {
    return stagingConfig[operator]
  }
  return defaultConfig
}

module.exports = selectConfig
