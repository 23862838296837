import React from 'react'
import Table from 'components/Table'
import { dateToLocale, timeToLocale } from 'components/Helpers'

const FreezeList = ({ freezePeriods, timezone }) =>
  <div className='index'>
    <div className='content-box invoices'>
      <Table
        className='freeze-list'
        data={freezePeriods}
        defaultSorted={[{ id: 'begin_date', desc: true }]}
        columns={[
          {
            Header: 'From',
            accessor: 'begin_date',
            Cell: props =>
              dateToLocale(props.value)
          },
          {
            Header: 'To',
            accessor: 'end_date',
            Cell: props =>
              dateToLocale(props.value)
          },
          {
            Header: 'Scheduled at',
            accessor: 'created_at',
            Cell: props =>
              timeToLocale(props.value, timezone)
          },
          {
            Header: 'Cancelled at',
            accessor: 'cancel_date',
            Cell: props =>
              props.value ? dateToLocale(props.value) : ''
          }
        ]}
      />
    </div>
  </div>

export default FreezeList
