import React from 'react'
import { useFormContext } from 'react-hook-form'

const Hidden = ({ name, value }) => {
  const { register } = useFormContext()

  return (
    <input type='hidden' ref={register} name={name} value={value} />
  )
}

export default Hidden
