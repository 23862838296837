import React, { useState } from 'react'
import useReport from './components/helpers/useReport'

/* eslint-disable camelcase */
const MembershipMovementByMonth = ({ location_id = '' }) => {
  const [reportArgs] = useState({ location_id })

  const { data, meta } = useReport('membership-movements-by-month', reportArgs)

  if (!data) return null

  return (
    <div className='report-wrapper with-padding'>
      <header>
        <h2>Membership movements this month</h2>
      </header>
      <table>
        <thead>
          <tr>
            {meta.map(column => <th key={column.name} style={{ textTransform: 'uppercase' }}>{column.name}</th>)}
          </tr>
        </thead>
        <tbody>
          {data.map((location, i) => {
            return (
              <tr key={i}>
                <td>{location.location}</td>
                <td>{location.age}</td>
                <td>{location.ib}</td>
                <td>{location.sales}</td>
                <td>{location.trial}</td>
                <td>{location.presale}</td>
                <td>{location.reactivation}</td>
                <td>{location.member_cancellation}</td>
                <td>{location.admin_cancellation}</td>
                <td>{location.missing_payment}</td>
                <td>{location.today}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default MembershipMovementByMonth
