import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AppContext from 'AppContext'
import Table, {
  defaultFilterMethod,
  filterAndLinkArrowColumn,
  Filters
} from 'components/Table'
import PaidPassAPI from 'services/api/paid-passes'
import { ActionButton } from 'components/Buttons'
import Amount from '../../components/Helpers/Amount'

const baseUrl = operatorId => `/operators/${operatorId}/paid-passes`

const columnDefinitions = (operator, setFilterable) => {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ original: distributor, value }) => {
        const path = `${baseUrl(operator.id)}/distributors/${distributor.id}`
        return (
          <Link to={{ pathname: path, state: { distributor } }}>
            {`${value}`}
          </Link>
        )
      },
      show: true
    },
    {
      Header: 'Contact',
      accessor: 'contact',
      Cell: props => props.original.contact,
      show: true
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      Cell: props => props.original.email,
      show: true
    },
    {
      Header: 'Monthly fee',
      accessor: 'monthly_fee',
      Cell: ({ value }) => <Amount amount={value} currency={operator.currency} />,
      show: false
    },
    {
      Header: 'Paid passes fee',
      accessor: 'paid_passes_fee',
      Cell: ({ value }) => <Amount amount={value} currency={operator.currency} />,
      show: false
    },
    {
      Header: 'Signup fee',
      accessor: 'signup_fee',
      Cell: ({ value }) => <Amount amount={value} currency={operator.currency} />,
      show: false
    },
    filterAndLinkArrowColumn(setFilterable, 'paid-passes/distributors')
  ]
}

const NewDistributorButton = ({ operator }) =>
  <span className='header-action-wrapper'>
    <ActionButton
      txt='New Distributor'
      link={`${baseUrl(operator.id)}/distributors/new`}
      icon='new white'
    />
  </span>

const DistributorList = () => {
  const { operator } = React.useContext(AppContext)
  const [distributors, setDistributors] = useState([])
  const [filterable, setFilterable] = useState(false)
  const [columns, setColumns] = useState(columnDefinitions(operator, setFilterable))

  useEffect(() => {
    PaidPassAPI.distributors.search(
      operator.id,
      {},
      result => setDistributors(result.data)
    )
  }, [operator])

  return (
    <div className='index'>
      <h1>
        Paid passes distributors
        <NewDistributorButton operator={operator} />
      </h1>
      <Filters toggleFiltered={setFilterable} {...{ columns, setColumns }} />
      <div className='content-box'>
        <Table
          data={distributors}
          loading={distributors.length < 1}
          {...{ columns, defaultFilterMethod, filterable }}
        />
      </div>
    </div>
  )
}

export default DistributorList
