import React from 'react'
import { Link } from 'react-router-dom'
import viewAdminUrl from './viewAdminUrl'

const baseUrl = operatorId => `/operators/${operatorId}/administrators`

const Separator = () => `${String.fromCharCode(160)}${String.fromCharCode(62)}${String.fromCharCode(160)}`

const Breadcrumbs = ({ operatorId, admin }) => {
  return (
    <>
      <span className='breadcrumbs'>
        <Link to={baseUrl(operatorId)}>Administrators</Link>
        {admin &&
          <>
            <Separator />
            <Link to={viewAdminUrl(admin.id, operatorId)}>{admin.profile.first_name} {admin.profile.last_name}</Link>
          </>}
      </span>
    </>
  )
}

export default Breadcrumbs
