import React from 'react'
import { Link } from 'react-router-dom'
import { ActionButton } from 'components/Buttons'
import { DetailBox } from 'components/Boxes'

const adminLink = (obj, operatorId) => {
  if (!obj.name) return ''

  return (
    <span>
      <Link to={`/operators/${operatorId}/administrators/${obj.id}`}>
        {obj.name}
      </Link>
    </span>
  )
}
const CorporateDetails = ({ promotion }) => {
  if (promotion.promotion_type !== 'corporate') return null
  const items = [
    { label: 'Company name', value: promotion.corporation.name },
    { label: 'Adddres', value: promotion.corporation, type: 'address' },
    { label: 'Date of contract signature', value: promotion.date_of_contract_signature, type: 'date' },
    { label: 'Administrator', value: adminLink(promotion.responsible_administrator, promotion.operator_id) }
  ]

  return (
    <DetailBox header='Corporate details' items={items}>
      <ActionButton
        txt='Edit corporate details'
        icon='edit white'
        link='/under-development'
      />
    </DetailBox>
  )
}

export default CorporateDetails
