import React from 'react'
import { ActionButton } from 'components/Buttons'
import { DetailBox } from 'components/Boxes'

// https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
const withHTML = (txt) =>
  <span dangerouslySetInnerHTML={{ __html: txt }} />

const CustomerPresentation = ({ promotion }) => {
  const items = [
    { label: 'My Page', value: withHTML(promotion.post_signup_presentation) },
    { label: 'Sign-up', value: withHTML(promotion.pre_signup_presentation) }
  ]

  if (promotion.promotion_type === 'referral') { items.push({ label: 'Referrer', value: withHTML(promotion.referrer_presentation) }) }

  return (
    <DetailBox header='Customer Presentation' items={items}>
      <ActionButton
        txt='Edit customer presentation'
        icon='edit white'
        link={`/operators/${promotion.operator_id}/promotions/${promotion.id}/presentation/edit`}
      />
    </DetailBox>
  )
}

export default CustomerPresentation
