const API = require('./api')
const baseURL = 'paid-passes'

const search = (path) => (operatorId, params, success) => {
  API.fetch(baseURL + '/' + path, success, params)
}

const create = (path) => (args, operatorId, success, error) => {
  const url = process.env.REACT_APP_SERVER + '/' + baseURL + '/' + path
  // let url = baseURL + '/' + path
  API.post(url, success, args, error)
}

const fetch = (path) => (id, params, success, error) => {
  const url = baseURL + '/' + path + '/' + id
  API.fetch(url, success, params, error)
}

const update = (path) => (id, body, success, error) => {
  const url = process.env.REACT_APP_SERVER + '/' + baseURL + '/' + path + '/' + id
  // let url = baseURL + '/' + path + '/' + id
  API.patch(url, success, body, error)
}

const download = path => (params, success, error) => {
  const url = baseURL + '/' + path + '/download'
  API.fetch(url, success, params, error)
}

module.exports = {
  distributors: {
    search: search('distributors'),
    create: create('distributors'),
    fetch: fetch('distributors'),
    update: update('distributors')
  },
  paidPasses: {
    search: search('paid-passes'),
    create: create('paid-passes'),
    fetch: fetch('paid-passes'),
    update: update('paid-passes'),
    download: download('paid-passes')
  }
}
