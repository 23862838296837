import React, { useState } from 'react'
import useReport from './components/helpers/useReport'
import { Button } from '../../components/Buttons'
import ReportPeriod from './components/ReportPeriod'
import { format, addMonths } from 'date-fns'

/* eslint-disable camelcase */
const MembershipRevenueByMonth = () => {
  const [currentMonth, setCurrentMonth] = useState(0)
  const [reportArgs, setReportArgs] = useState(ReportPeriod.monthAtIndex(currentMonth))

  const previousMonth = () => {
    setCurrentMonth(currentMonth - 1)
    setReportArgs(ReportPeriod.monthAtIndex(currentMonth - 1))
  }

  const nextMonth = () => {
    setCurrentMonth(currentMonth + 1)
    setReportArgs(ReportPeriod.monthAtIndex(currentMonth + 1))
  }

  const { data, meta, totals } = useReport('membership-revenue-by-month', reportArgs)

  if (!data) return null

  return (
    <div className='report-wrapper with-padding'>
      <header>
        <Button txt='Previous' onClick={previousMonth} />
        <h3>{format(addMonths(new Date(), currentMonth), 'MMMM YYYY')}</h3>
        <Button txt='Next' onClick={nextMonth} />
      </header>
      <table>
        <thead>
          <tr>
            {meta.map(column => <th key={column.name} style={{ textTransform: 'uppercase' }}>{column.name}</th>)}
          </tr>
        </thead>
        <tbody>
          {data.map((location, i) => {
            return (
              <tr key={i}>
                <td>{location.date}</td>
                <td>{location.location}</td>
                <td>{location.recurring}</td>
                <td>{location.signup}</td>
                <td>{location.rest_of_month}</td>
                <td>{location.add_on}</td>
                <td>{location.total}</td>
                <td>{location.refunded}</td>
              </tr>
            )
          })}
          <tr className='totals'>
            <td>{totals.date}</td>
            <td>{totals.location}</td>
            <td>{totals.recurring}</td>
            <td>{totals.signup}</td>
            <td>{totals.rest_of_month}</td>
            <td>{totals.add_on}</td>
            <td>{totals.total}</td>
            <td>{totals.refunded}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default MembershipRevenueByMonth
