import React, { useState, useEffect } from 'react'
import PaidPassAPI from 'services/api/paid-passes'
import { Redirect } from 'react-router-dom'

import {
  Form,
  FormLine,
  TextInput,
  EmailInput,
  Mobile,
  Submit
} from 'components/Forms'

import ErrorMessage from 'components/Forms/components/ErrorMessage'
import PaidPassBreadCrumbs from './components/PaidPassBreadCrumbs'

const baseUrl = (operatorId) => `/operators/${operatorId}/paid-passes`

const url = (operatorId, id) => {
  return `${baseUrl(operatorId)}/distributors/${id}`
}

const DistributorEdit = ({ match }) => {
  const { id, operatorId } = match.params
  const [item, setItem] = useState(null)
  const [updatedId, setUpdatedId] = useState(null)
  const [validationErrors, setValidationErrors] = useState({})

  useEffect(() => {
    PaidPassAPI.distributors.fetch(id, null, setItem)
  }, [id, operatorId])

  if (!item) return null

  const onSubmit = data => {
    PaidPassAPI.distributors.update(
      id,
      { id, ...data, source: 'admin-ui' },
      successfulResult => setUpdatedId(successfulResult.id),
      errorResult => setValidationErrors(errorResult)
    )
  }

  if (updatedId) return <Redirect to={url(operatorId, updatedId)} />

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <PaidPassBreadCrumbs
          operatorId={operatorId}
          list={[{ path: url(operatorId, item.id), text: item.name }]}
        />
        Edit paid pass distributor
      </h1>
      <div className='content-box form'>
        <Form onSubmit={onSubmit} defaultValues={item} validationErrors={validationErrors.errors}>
          <TextInput name='name' />
          <TextInput name='organization_number' />
          <TextInput name='street' />
          <FormLine>
            <TextInput name='postal_code' />
            <TextInput name='postal_location' />
          </FormLine>
          <TextInput name='contact' />
          <Mobile />
          <EmailInput name='email' />
          <TextInput name='signup_fee' />
          <TextInput name='monthly_fee' />
          <TextInput name='paid_passes_fee' />
          <Submit label='Update distributor' />
          <ErrorMessage name='message' errors={validationErrors} />
        </Form>
      </div>
    </div>
  )
}

export default DistributorEdit
