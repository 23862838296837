import React from 'react'
import AppContext from '../../AppContext'

export default ({ match }) => {
  // const currentUser = JSON.parse(window.localStorage.getItem('currentAdmin'))
  // const currentUsername = currentUser ? currentUser.username : null

  let env = 'staging'
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = 'production'
  }

  const token = window.localStorage.getItem('auth-token')
  const { operator: { id, timezone } } = React.useContext(AppContext)

  // We may have to wait for AppContext to be available
  if (!id) {
    return (
      <div className='index facility-management'>
        <p>waiting for operator...</p>
      </div>
    )
  }

  // Must leave empty h1 there for element below not to be cropped...
  return (
    <div className='index facility-management'>
      {/* eslint-disable-next-line */}
      <h1></h1>
      <cl-facility-management
        mountPath={`/operators/${id}/maintenance`}
        apihost={`https://facility-management.${env}.credlock.net`}
        operatorid={id}
        timezone={timezone}
        token={token}
      />
    </div>
  )
}
