import React from 'react'
import { Link } from 'react-router-dom'
import viewPromotionUrl from './viewPromotionUrl'

const baseUrl = operatorId => `/operators/${operatorId}/promotions`

const Separator = () => `${String.fromCharCode(160)}${String.fromCharCode(62)}${String.fromCharCode(160)}`

const Breadcrumbs = ({ operatorId, promotion }) => {
  return (
    <>
      <span className='breadcrumbs'>
        <Link to={baseUrl(operatorId)}>Promotions</Link>
        {promotion &&
          <>
            <Separator />
            <Link to={viewPromotionUrl(promotion.id, operatorId)}>{promotion.name}</Link>
          </>}
      </span>
    </>
  )
}

export default Breadcrumbs
