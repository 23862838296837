import React from 'react'

import humanize from './humanize'
import ErrorMessage from './ErrorMessage'
import { useFormContext } from 'react-hook-form'

const RadioInput = ({ label, name, value, required = false, checked = false }) => {
  const { register, errors } = useFormContext()

  return (
    <label className='radio-container' style={{ display: 'flex', alignItems: 'center', justiyContent: 'center' }}>
      <input
        type='radio'
        style={{ minWidth: '1.5em' }}
        className={errors[name] ? 'error' : ''}
        name={name}
        value={value}
        defaultChecked={checked}
        ref={
          register({ required: required })
        }
      />
      {label || humanize(value)}
      <ErrorMessage name={name} errors={errors} />
    </label>
  )
}

export default RadioInput
