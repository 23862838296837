import React from 'react'

const centToDecimal = int => (int / 100).toFixed(2)

const toHumanReadable = (amount, currency) => {
  let res
  switch (currency) {
    case 'CHF':
    case 'EUR':
    case 'NOK':
      res = centToDecimal(amount)
      break
    default:
      // Ignore
  }

  return currency + ' ' + res
}

const Amount = ({ amount, currency, ...rest }) => {
  if (amount === null) return '-'

  return (
    <span {...rest}>
      {toHumanReadable(amount, currency)}
    </span>
  )
}

export default Amount

export {
  toHumanReadable
}
