import React, { useState, useEffect } from 'react'
import {
  ActivationAndChurnHistory,
  IBHistoryWithRollingAverage,
  ActiveVsFreezeIBHistory,
  TrialConversionHistory,
  Status,
  MembershipAgeStatus,
  MembershipMovementsByMonth,
  MembershipRevenueByMonth,
  ActivationsByCategory,
  TrialMembershipsByDaysUntilActivation,
  MissingMembershipFees,
  TrialStatusBySignupDate,
  ActivationStatus,
  ChurnStatus,
  TrialStatus
} from 'components/Reports' // legacy reports

// Legacy reports must match legacy report included
// in reports service list component
const legacyReports = {
  'membership-revenue-by-month': <MembershipRevenueByMonth />,
  'missing-membership-fees': <MissingMembershipFees />,
  'trial-memberships-by-days-until-activation': <TrialMembershipsByDaysUntilActivation />,
  'activation-and-churn-history': <ActivationAndChurnHistory />,
  'ib-history-with-rolling-average': <IBHistoryWithRollingAverage />,
  'active-vs-freeze-ib-history': <ActiveVsFreezeIBHistory />,
  'trial-conversion-history': <TrialConversionHistory />,
  status: <Status />,
  'membership-age-status': <MembershipAgeStatus />,
  'membership-movements-by-month': <MembershipMovementsByMonth />,
  'activations-by-category': <ActivationsByCategory />,
  'trial-status-by-signup-date': <TrialStatusBySignupDate />,
  'activation-status': <ActivationStatus />,
  'trial-status': <TrialStatus />,
  'churn-status': <ChurnStatus />
}

const Report = ({ match }) => {
  const { params: { name } } = match
  const id = name

  const [report, setReport] = useState(null)

  useEffect(() => {
    let env = 'staging'
    if (/.*admin.evofitness.*/.test(window.location.origin)) {
      env = 'production'
    }

    fetch(`https://admin-ui.${env}.credlock.net/reports/${id}`)
      .then(data => data.json())
      .then(json => setReport(json))
      .catch(console.log)
  }, [id])

  if (Object.keys(legacyReports).includes(name)) {
    return (
      <div className='index'>
        <h1>Reports</h1>
        <div className='content-box'>
          {legacyReports[name]}
        </div>
      </div>
    )
  }

  if (!report) { return null }

  return (
    <div className='index' style={{ padding: '5em 2em' }}>
      <reports-show id={report.id} />
    </div>
  )
}

export default Report
