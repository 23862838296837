import React from 'react'
import Amount from 'components/Helpers/Amount'

const monthHasDiscount = (schedule) =>
  schedule.fee_as_amount != null && schedule.fee_as_percentage !== 100

const monthsWithDiscount = (scheduleArray) =>
  scheduleArray.filter(s =>
    monthHasDiscount(s)
  )

const fixedAmountDiscount = (scheduleEntry) =>
  scheduleEntry.fee_as_amount != null

const PromotionAmountOrPercentage = ({ scheduleEntry, currency }) => {
  if (fixedAmountDiscount(scheduleEntry)) {
    return (
      <Amount
        currency={currency}
        amount={scheduleEntry.fee_as_amount}
      />
    )
  } else {
    return <span>{scheduleEntry.fee_as_percentage}</span>
  }
}

// Hack in the wrong place.
//
// The backend cannot handle strings for integer. And dry-validation in
// an old version is not playing along. As a workaround we convert strings
// to integers on client before form submit.
const sanitizePaymentSchedule = (monthly_payment_schedule) => {
  const toInteger = value => (value || value === 0) ? parseInt(value) : null
  const toMoney = (value) => amountToInteger(value)

  return monthly_payment_schedule.map(entry => {
    const result = {}
    result.begin_month_index = toInteger(entry.begin_month_index)
    if (result.begin_month_index !== 37) {
      result.end_month_index = toInteger(entry.end_month_index)
    }
    result.fee_as_percentage = toInteger(entry.fee_as_percentage)
    result.fee_as_amount = toMoney(entry.fee_as_amount)
    console.log('converted', entry.fee_as_amount, 'to', result.fee_as_amount)
    return result
  })
}

const promotionWithDecimalAmounts = (promotion) => {
  const schedule = promotion.monthly_payment_schedule.map(e =>
    ({ ...e, fee_as_amount: amountToDecimal(e.fee_as_amount) })
  )

  return {
    ...promotion,
    monthly_payment_schedule: schedule
  }
}

/**
 *
 * Converts an integer to a decimal with max 2 decimals
 *
 * Example:
 *    10000 == 100
 *    10020 == 100.2
 *    10022 == 100.22
 *    0 = 0
 *    null = null
 *
 * Used to change amounts to displayed in the UI with commas instead of as a full integer.
 */
const amountToDecimal = (amount) => {
  if (amount) {
    // +parseFloat to force to a number instead of a string
    // this will make the result have between
    // 0 and 2 decimals values instead of always 2
    return +parseFloat(amount / 100).toFixed(2)
  }

  return amount
}

/**
 *
 * Takes a string and converts to an integer
 * Accepts both comma and dot as decimal separator
 *
 * Example:
 *   '100.20' == 10020
 *   '100,20' == 10020
 *   '100' == 10000
 *
 * Previous implementation used:
 *  return parseFloat(value.replace(/,/g, '.') ) * 100
 *
 * This sometimes results in weird behaviour like returning
 *   1019.9999999999999 when inputting '10.20'.
 *
 * We therefore do some extra steps to avoid this.
 * More information about rounding error for parseFloat()
 *  https://stackoverflow.com/questions/3650081/why-does-the-sum-of-2-parsefloat-variables-give-me-an-incorrect-decimal-number
 */
const amountToInteger = (value) => {
  if (!value && value !== 0) { return null }

  // Converts the value from a string to a float,
  // but also replaces ',' with '.'
  // so that parseFloat includes the decimals even when ',' used
  const decimalNumber = parseFloat(value.replace(/,/g, '.'))

  // This is the line that ensures that we fix the
  // rounding issue for parseFloat, but since this returns a string
  // we have to use parseInt when returning
  const integerString = (decimalNumber * 100).toFixed(0)

  return parseInt(integerString)
}

export {
  monthHasDiscount,
  monthsWithDiscount,
  fixedAmountDiscount,
  PromotionAmountOrPercentage,
  sanitizePaymentSchedule,
  promotionWithDecimalAmounts,
  amountToInteger // Only exported to be able to test separately
}
