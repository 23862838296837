import React from 'react'
import { useForm, FormContext } from 'react-hook-form'

// defaultValues: Object. Use to populate form, may be an existing entity.
// onSubmit: Function. Use to submit form data.
// validationErrors: Object. Server side validation errors where key maps to field name.
// children: the nested form elements.
export default function Form ({
  defaultValues,
  onSubmit,
  validationErrors,
  children
}) {
  const methods = useForm({ defaultValues })

  // Merge server side validation errors into form
  methods.errors = {
    ...methods.errors,
    ...validationErrors
  }
  const { handleSubmit } = methods

  return (
    <FormContext {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete='off'
      >
        {children}
      </form>
    </FormContext>
  )
}
