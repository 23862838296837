import React, { Component } from 'react'
import Router from '../Router'

// https://github.com/fkhadra/react-toastify#le-style
// https://fkhadra.github.io/react-toastify/
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { Header, Sidebar } from 'components/Navigations/index'
import Login from 'scenes/Login'
import jwt from '@credlock-x/jwt-node'
import AppContext from 'AppContext'
import { fetchOperators } from 'services/api/operators'
import { fetchLocations } from 'services/api/locations'
import AuthAPI from 'services/api/auth'

const operatorsForUser = AuthAPI.findAdmins

class Main extends Component {
  constructor (props) {
    super(props)

    this.state = {
      mobile_nav_hidden: true,
      mobile_nav_class: 'hidden',
      selected_operator: undefined,
      operators: [],
      authToken: props.authToken,
      appContext: {
        operator: {},
        user: {},
        currentAdmin: props.currentAdmin
      }
    }

    this._changeOperator = this._changeOperator.bind(this)
    this._toggleMobileNav = this._toggleMobileNav.bind(this)
    this._login = this._login.bind(this)
  }

  componentDidMount () {
    if (!this.state.authToken) return

    // FIXME: This is very hackish...
    // We fetch all operators, then we fetch
    // all operators for authenticated user
    // Then we find out which operator the user is logged in for
    // and then we fetch the locations for this operator
    //   (used in filters and exports)
    // This is also partly a duplicate of what is done on log in
    fetchOperators((operators) => {
      const args = { username: this.state.appContext.currentAdmin.username }

      operatorsForUser(args, (res) => {
        const operator =
          operators.find(o => o.id === this._user().operator) || {}

        fetchLocations(operator.id, {}, (locations) => {
          const operatorsForUser = operators.filter(o => res.includes(o.id))

          this.setInitState(operatorsForUser, operator, locations.data)
        })
      }, (err) => {
        console.error('Error in fetching operators for user', err)
      })
    }, (err) => {
      this.setState({ error: err })
      console.error('Error for fetch all operators', err)
    })

    // Set selected operator based on url path
    const url = this._url()
    if (url.operatorID) {
      this.setState({ selected_operator: url.operatorID })
    }
  }

  render () {
    if (!this.state.authToken) {
      return (<Login login={this._login} />)
    }

    let env = 'staging'
    if (/.*admin.evofitness.*/.test(window.location.origin)) {
      env = 'production'
    }

    return (
      <div className='application'>
        <AppContext.Provider value={this.state.appContext}>
          <Header
            operators={this.state.operators}
            methods={{
              change_operator: this._changeOperator,
              toggle_mobile_nav: this._toggleMobileNav
            }}
            error={this.state.error}
          />
          <Sidebar
            selected_operator={this.state.selected_operator}
            mobile_nav_hidden={this.state.mobile_nav_hidden}
            toggle_mobile_nav={this._toggleMobileNav}
            permissions={this._permissions()}
            logout={this.props.logout}
          />
          <cl-donedone-conversation
            token={this.state.authToken}
            apihost={`https://donedone.${env}.credlock.net`}
          />
          <Router
            selected_operator={this.state.selected_operator}
            user={this._user()}
          />
          <ToastContainer
            position='bottom-center'
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </AppContext.Provider>
      </div>
    )
  }

  setInitState (operators, operator, locations) {
    this.setState({
      appContext: {
        operator: operator,
        user: this._user(),
        currentAdmin: this.props.currentAdmin,
        locations: locations
      },
      operators: operators,
      selected_operator: operator.id
    })
  }

  _user () {
    return jwt.ignoreSignature(this.state.authToken)
  }

  _permissions () {
    return this._user().permissions
  }

  _login (token) {
    this.setState({ authToken: token })
  }

  _toggleMobileNav () {
    this.setState({ mobile_nav_hidden: !this.state.mobile_nav_hidden })
  }

  _changeOperator (event) {
    event.preventDefault()
    return alert('You must sign out and in again to switch operator')
  }

  _url () {
    const urlElements = window.location.pathname.split('/')

    return {
      elements: urlElements,
      base_path: urlElements[1],
      operatorID: urlElements[2]
    }
  }
}

export default Main
