import React from 'react'
import { Link } from 'react-router-dom'
import StatusFilter from '../filters/status'
import GenderFilter from '../filters/gender'
import {
  capitalizeFirstLetter,
  dateToLocale,
  excelDate
} from 'components/Helpers'
import AppContext from 'AppContext'
import LocationsFilter from 'components/Table/components/Filters/locations'
import { filterAndLinkArrowColumn } from 'components/Table'

const defaultVisible = [
  'oid',
  'customer.first_name',
  'customer.last_name',
  'status',
  'customer.email',
  'customer.mobile',
  'primary_location.name',
  'promotion.name'
]

const MembershipLink = ({ original, value }) => {
  const { operator } = React.useContext(AppContext)

  const url = `/operators/${operator.id}/members/${original.id}`

  return (
    <Link to={{ pathname: url, state: { obj: original } }}>
      {value}
    </Link>
  )
}

const personaliaColumns = (isVisible) => {
  return {
    first_name: {
      Header: 'First name',
      accessor: 'customer.first_name',
      show: isVisible('customer.first_name'),
      Cell: props => <MembershipLink {...props} />
    },
    last_name: {
      Header: 'Last name',
      accessor: 'customer.last_name',
      show: isVisible('customer.last_name'),
      Cell: props => <MembershipLink {...props} />
    },
    email: {
      Header: 'Email',
      accessor: 'customer.email',
      show: isVisible('customer.email'),
      hide_for: {
        phone: true
      }
    },
    mobile: {
      Header: 'Mobile',
      accessor: 'customer.mobile',
      show: isVisible('customer.mobile'),
      Export: ({ customer: { mobile } }) =>
        mobile ? `${mobile.prefix} ${mobile.number}` : null,
      Cell: ({ value }) => `${value.prefix} ${value.number}`
    },
    street: {
      Header: 'Street',
      accessor: 'customer.address.street',
      show: isVisible('customer.address.street')
    },
    postal_code: {
      Header: 'ZIP',
      accessor: 'customer.address.postal_code',
      show: isVisible('customer.address.postal_code')
    },
    postal_location: {
      Header: 'Postal location',
      accessor: 'customer.address.postal_location',
      show: isVisible('customer.address.postal_location')
    },
    gender: (setFiltered, filtered) => ({
      Header: 'Gender',
      accessor: 'customer.gender',
      show: isVisible('customer.gender'),
      Filter: () =>
        <GenderFilter
          onChange={setFiltered}
          filtered={filtered}
        />
    })
  }
}

const membershipColumns = (isVisible) => {
  const column = (accessor, header = null) => ({
    Header: header || capitalizeFirstLetter(accessor.split('_').join(' ')),
    accessor: accessor,
    show: isVisible(accessor)
  })

  return {
    id: {
      Header: 'ID',
      accessor: 'oid',
      show: isVisible('oid')
    },
    status: (setFiltered, filtered) => ({
      Header: 'Status',
      accessor: 'status',
      show: isVisible('status'),
      Filter: () =>
        <StatusFilter
          onChange={setFiltered}
          filtered={filtered}
        />
    }),
    location: (setFiltered, filtered) => ({
      Header: 'Primary location',
      accessor: 'primary_location.name',
      id: 'location.name',
      show: isVisible('primary_location.name'),
      Filter: () =>
        <LocationsFilter
          onChange={setFiltered}
          filtered={filtered}
        />
    }),
    created_at: {
      Header: 'Sales date',
      accessor: 'created_at',
      show: isVisible('created_at'),
      filterable: false,
      exportFormat: { numFmt: 'dd/mm/yyyy' },
      Export: ({ created_at }) => excelDate(created_at),
      Cell: ({ value }) => <span>{dateToLocale(value)}</span>
    },
    begin_date: {
      Header: 'Begin date',
      accessor: 'begin_date',
      filterable: false,
      show: isVisible('begin_date'),
      exportFormat: { numFmt: 'dd/mm/yyyy' },
      Export: ({ begin_date }) => excelDate(begin_date),
      Cell: ({ value }) => <span>{dateToLocale(value)}</span>
    },
    cancel_date: {
      Header: 'Cancel date',
      accessor: 'cancel_date',
      sortable: false,
      filterable: false,
      show: isVisible('cancel_date'),
      exportFormat: { numFmt: 'dd/mm/yyyy' },
      Export: ({ cancel_date }) => excelDate(cancel_date),
      Cell: ({ value }) => <span>{dateToLocale(value)}</span>
    },
    end_date: {
      Header: 'End date',
      accessor: 'end_date',
      filterable: false,
      show: isVisible('end_date'),
      exportFormat: { numFmt: 'dd/mm/yyyy' },
      Export: ({ end_date }) => excelDate(end_date),
      Cell: ({ value }) => <span>{dateToLocale(value)}</span>
    },
    referral_code: column('referral_code'),
    trial_days: column('trial_days'),
    promotion: {
      name: {
        Header: 'Promotion name',
        accessor: 'promotion.name',
        show: isVisible('promotion.name'),
        style: { whiteSpace: 'unset' }
      },
      type: {
        Header: 'Promotion type',
        accessor: 'promotion.promotion_type',
        show: isVisible('promotion.promotion_type')
      }
    },
    product: {
      name: {
        Header: 'Product name',
        accessor: 'product.name',
        show: isVisible('product.name'),
        style: { whiteSpace: 'unset' }
      }
    }
  }
}

const paymentMethod = (isVisible) => {
  return {
    details: {
      Header: 'Credit Card number',
      accessor: 'current_payment_method.details',
      show: isVisible('current_payment_method.details'),
      minWidth: 130
    }
  }
}

const buildColumns = (filtered, setFiltered, setFilterable, stateColumns) => {
  // This is some weird hack to prevent from resetting the columns.
  // Not sure why this works, or why everything falls apart without it.
  if (stateColumns && stateColumns !== 'default' && stateColumns.length < 1) { return }

  const isVisible = (accessor) => {
    const columns = stateColumns !== 'default' ? stateColumns : []
    const column = columns.find(c => c.accessor === accessor)

    if (!column && defaultVisible.includes(accessor)) { return true }

    return column ? column.show : false
  }

  const membership = membershipColumns(isVisible)
  const personalia = personaliaColumns(isVisible)
  const pmMethod = paymentMethod(isVisible)

  return [
    membership.id,
    personalia.first_name,
    personalia.last_name,
    personalia.gender(setFiltered, filtered),
    membership.status(setFiltered, filtered),
    personalia.email,
    personalia.mobile,
    personalia.street,
    personalia.postal_code,
    personalia.postal_location,
    membership.location(setFiltered, filtered),
    membership.created_at,
    membership.begin_date,
    membership.cancel_date,
    membership.end_date,
    membership.referral_code,
    membership.trial_days,
    membership.promotion.name,
    membership.promotion.type,
    membership.product.name,
    pmMethod.details,
    filterAndLinkArrowColumn(setFilterable, 'members')
  ]
}

export default buildColumns
