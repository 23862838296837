const API = require('./api')

const baseURL = 'memberships'

const fetchMembers = (operatorID, state, success) => {
  API.fetch(baseURL, success, state)
}

const fetchMember = (operatorID, memberID, success) => {
  const url = baseURL + '/' + memberID
  API.fetch(url, success)
}

// A little bit hacky here, passing the memberID to the 'customers' endpoint to
// update the profile, as memberships is going to handle getting customer from
// the memberID. a bit of a part-way step to the denormalizing membership::customer
// relationship and making it 1:1
// TODO: rename endpoint to /profiles/ or similar, but requires more changes in
// admin-api to handle the change cleanly
const updateMember = (body, operatorID, memberID, success, error) => {
  const url = process.env.REACT_APP_SERVER + '/customers/' + memberID
  API.patch(url, success, body, error)
}

const stopMember = (args, operatorID, memberID, success, error) => {
  const url =
    process.env.REACT_APP_SERVER + '/memberships/' + memberID + '/stop'
  API.post(url, success, args, error)
}

const anonymizeMember = (args, operatorID, memberID, success, error) => {
  const url =
    process.env.REACT_APP_SERVER + '/memberships/' + memberID + '/anonymize'
  API.post(url, success, args, error)
}

const updateSheltered = (args, operatorID, memberID, success, error) => {
  const url =
    process.env.REACT_APP_SERVER + '/memberships/' + memberID + '/sheltered'
  API.patch(url, success, args, error)
}

const updatePromotion = (args, operatorID, memberID, success, error) => {
  const url = process.env.REACT_APP_SERVER + '/memberships/' + memberID + '/promotion'
  API.patch(url, success, args, error)
}

const referrals = (memberID, success) => {
  const url = baseURL + '/' + memberID + '/referrals'
  API.fetch(url, success)
}

const memberToken = (memberId, operatorId, success) => {
  const url = baseURL + '/authenticate'
  const params = {
    membership_id: memberId,
    operator_id: operatorId
  }
  return API.fetch(url, success, params)
}

module.exports = {
  fetchMembers: fetchMembers,
  fetchMember: fetchMember,
  updateMember: updateMember,
  stopMember: stopMember,
  anonymizeMember: anonymizeMember,
  updateSheltered: updateSheltered,
  updatePromotion: updatePromotion,
  referrals: referrals,
  memberToken
}
