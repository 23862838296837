import React from 'react'
import { Badge, StatusBadge } from '../../components/Badges'

const BadgesDemo = () =>
  <div>
    <br />
    <hr />
    <h1>Badges</h1>
    <Badge className='primary' txt={24} />
    <Badge className='secondary' txt={24} />
    <Badge className='accent-3' txt={24} />
    <Badge className='accent-1' txt={24} />
    <Badge className='accent-2' txt={24} />
    <Badge className='black' txt={24} />
    <Badge className='white' txt={24} />
    <br />
    <br />
    <h2>With icons</h2>
    <Badge className='primary' txt={24} icon={{ icon: 'user' }} />
    <Badge className='secondary' txt={24} icon={{ icon: 'user' }} />
    <Badge className='accent-3' txt={24} icon={{ icon: 'user' }} />
    <Badge className='accent-1' txt={24} icon={{ icon: 'user' }} />
    <Badge className='accent-2' txt={24} icon={{ icon: 'user' }} />
    <Badge className='black' txt={24} icon={{ icon: 'user' }} />
    <Badge className='white' txt={24} icon={{ icon: 'user', color: 'black' }} />
    <br /><br />
    <h2>Statuses</h2>
    <StatusBadge status='active' />
    <StatusBadge status='charged' />
    <StatusBadge status='inactive' />
    <StatusBadge status='presale' />
    <StatusBadge status='declined' />
    <br />
    <br />
  </div>

export default BadgesDemo
