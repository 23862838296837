import React from 'react'

import { Badge } from 'components/Badges'

const InvoiceNumberWithBadge = ({ invoice }) => {
  return (
    <h2>
      Invoice {invoice.oid}
      &nbsp;
      <Badge className={`status ${invoice.status}`} txt={invoice.status.toUpperCase()} />
    </h2>
  )
}

export default InvoiceNumberWithBadge
