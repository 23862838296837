import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import AppContext from 'AppContext'
import { ActionButton } from 'components/Buttons'
import { DetailBox } from 'components/Boxes'
import PaidPassAPI from 'services/api/paid-passes'
import Amount from 'components/Helpers/Amount'
import PaidPassesList from './PaidPassesList'
import PaidPassBreadCrumbs from './components/PaidPassBreadCrumbs'

const baseUrl = operatorId => `/operators/${operatorId}/paid-passes`

const Details = ({ active, item, operator }) => {
  if (!active) return null

  const { id: operatorId, currency } = operator

  return (
    <div className='box-wrapper'>
      <DetailBox
        header='Details'
        items={[
          { label: 'Name', value: item.name },
          { label: 'Organization no', value: item.organization_number },
          { label: 'Street', value: item.street },
          { label: 'Postal code', value: `${item.postal_code} ${item.postal_location}` }
        ]}
      />
      <DetailBox
        header='Billing'
        items={[
          { label: 'Signup fee', value: fee(item.signup_fee, currency) },
          { label: 'Monthly fee', value: fee(item.monthly_fee, currency) },
          { label: 'Paid passes fee', value: fee(item.paid_passes_fee, currency) }
        ]}
      />
      <DetailBox
        header='Contact'
        items={[
          { label: 'Name', value: item.contact },
          { label: 'Mobile', value: `${item.mobile_prefix} ${item.mobile_number}` },
          { label: 'E-mail', value: item.email }
        ]}
      />
      <DetailBox style={{ backgroundColor: 'transparent' }}>
        <ActionButton
          txt='Edit distributor'
          link={`${baseUrl(operatorId)}/distributors/${item.id}/edit`}
          icon='edit white'
        />
      </DetailBox>
    </div>
  )
}

const Navigation = ({ page, setPage }) => {
  const NavLinkItem = ({ text, name }) => {
    const isActive = () => page === name
    const onClick = () => setPage(name)

    return (
      <NavLink to='#' isActive={isActive} onClick={onClick}>
        {text}
      </NavLink>
    )
  }

  return (
    <div className='menu-bar'>
      <ul>
        <li>
          <NavLinkItem text='Details' name='details' />
        </li>
        <li>
          <NavLinkItem text='Paid passes' name='passes' />
        </li>
      </ul>
    </div>
  )
}

const Distributor = ({ match }) => {
  const { id } = match.params
  const { operator } = React.useContext(AppContext)
  const [item, setItem] = useState(null)
  const [page, setPage] = useState('details')

  useEffect(() => {
    PaidPassAPI.distributors.fetch(id, null, setItem)
  }, [id, operator])

  if (!item) return null

  // A little hack to ensure that we have the operator
  // from the context before rendering sub components
  if (Object.keys(operator).length === 0) return null

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <PaidPassBreadCrumbs operatorId={operator.id} />

        {`${item.name}`}

        <span className='header-action-wrapper'>
          <ActionButton
            txt='Generate paid passes'
            link={`${baseUrl(operator.id)}/distributors/${item.id}/paid-passes/new`}
            icon='new white'
            state={{ obj: item }}
          />
        </span>
      </h1>
      <Navigation {...{ page, setPage }} />
      <Details
        {...{ item, operator }}
        active={page === 'details'}
      />
      <PaidPassesList
        distributorId={item.id}
        active={page === 'passes'}
        operator={operator}
      />
    </div>
  )
}

const fee = (amount, currency) =>
  <Amount
    amount={amount}
    currency={currency}
  />

export default Distributor
