import React, { useState } from 'react'
import Report from './components/Report'
import CHART_COLORS from './components/ChartJSColors'
import useReport from './components/helpers/useReport'

const MembershipAgeStatus = ({ location_id = '' }) => {
  const [reportArgs] = useState({ location_id })

  const { data, meta } = useReport('dashboard-membership-age', reportArgs)

  if (!data) return null

  return (
    <Report
      title='membership age'
      chartData={toChartJS(data)}
      excelData={{ meta, data }}
      style={{ isFilled: false, withPadding: true }}
    />
  )
}

const toChartJS = data => {
  return {
    type: 'bar',
    data: {
      labels: data.map(age => age.membership_age),
      datasets: [
        {
          label: 'male',
          barPercentage: 0.4,
          data: data.map(age => age.male),
          backgroundColor: CHART_COLORS.secondary
        },
        {
          label: 'female',
          barPercentage: 0.4,
          data: data.map(age => age.female),
          backgroundColor: CHART_COLORS.primary
        }
      ]
    }
  }
}

export default MembershipAgeStatus
