import React from 'react'
import { PrivateRoute } from 'components/WithAuth'
import {
  ScheduledInvoiceList,
  InvoiceList,
  Invoice,
  InvoicePayments,
  Details,
  Log,
  LogBeta,
  Keys,
  EditPersonalia,
  ManageMember,
  Product,
  Promotion,
  FreezeList,
  Referrals,
  Comments
} from '../../index'
import { NoAccessComponent } from 'scenes/NoAccess'
import { UnderDevelopmentComponent } from 'scenes/UnderDevelopment'
import { NavLink } from 'react-router-dom'

const IndexNavItem = ({ basePath, txt }) =>
  <li>
    <NavLink exact to={basePath}>
      {txt}
    </NavLink>
  </li>

const NavItem = ({ basePath, match, path, label = null }) =>
  <li>
    <NavLink to={`${basePath}/${path}`}>
      {label || path}
    </NavLink>
  </li>

const MainNavItems = (props) => {
  // Only show Log (BETA) to selected users
  const currentUser = JSON.parse(localStorage.getItem('currentAdmin')).username
  const shouldIncludeLogBeta = [
    'teknisk@fitnessgroup.no',
    'ronja.kvaloe@fitnessgroup.no'
  ].includes(currentUser)

  return (
    <ul>
      <IndexNavItem txt='Details' {...props} />
      <NavItem path='invoices' {...props} />
      <NavItem path='log' {...props} />
      {shouldIncludeLogBeta ? <NavItem path='log-beta' label='Log (BETA)' {...props} /> : null}
      <NavItem path='freeze' {...props} />
      <NavItem path='keys' {...props} />
      <NavItem path='referrals' {...props} />
      <NavItem path='comments' {...props} />
    </ul>
  )
}

const ManageMemberNavItems = (props) =>
  <ul>
    <IndexNavItem txt='General' {...props} />
    <NavItem path='product' {...props} />
    <NavItem path='promotions' {...props} />
    <NavItem path='addons' {...props} />
  </ul>

const Navigation = (props) => {
  switch (props.subPage) {
    case 'Edit Personalia':
      return ''
    case 'Manage membership':
      return (<ManageMemberNavItems basePath={`${props.match.url}/manage`} />)
    default:
      return (<MainNavItems basePath={props.match.url} {...props} />)
  }
}

const ManageMemberRoute = ({ component, path, ...props }) =>
  <PrivateRoute
    exact path={`${props.match.url}/${path}`}
    permission='customers.customer.update'
    component={component}
    header='Manage Member'
    {...props}
  />

const ManageMemberRoutes = (props) =>
  <span>
    <ManageMemberRoute
      path='manage'
      component={ManageMember}
      {...props}
    />
    <ManageMemberRoute
      path='manage/product'
      component={Product}
      {...props}
    />
    <ManageMemberRoute
      path='manage/promotions'
      component={Promotion}
      {...props}
    />
    <ManageMemberRoute
      path='manage/addons'
      component={UnderDevelopmentComponent}
      {...props}
    />
    <ManageMemberRoute
      path='manage/no-access'
      component={NoAccessComponent}
      {...props}
    />
  </span>

const MemberRouteAndNavigation = (props) =>
  <div className='menu-bar'>
    <Navigation {...props} />
    <PrivateRoute
      exact path={`${props.match.url}`}
      permission='memberships.member.get'
      component={Details}
      {...props}
    />
    <PrivateRoute
      exact path={`${props.match.url}/edit`}
      permission='customers.customer.update'
      component={EditPersonalia}
      header='Edit Personalia'
      {...props}
    />
    <ManageMemberRoutes {...props} />
    <PrivateRoute
      exact path={`${props.match.url}/invoices`}
      permission='billing.invoice.list'
      component={InvoiceList}
      {...props}
    />
    <PrivateRoute
      exact path={`${props.match.url}/scheduled-invoices`}
      permission='billing.invoice.list'
      component={ScheduledInvoiceList}
      {...props}
    />
    <PrivateRoute
      exact path={`${props.match.url}/invoices/:invoiceID`}
      permission='billing.invoice.get'
      component={Invoice}
      {...props}
    />
    <PrivateRoute
      exact path={`${props.match.url}/invoices/:invoiceID/payments`}
      permission='billing.invoice.get'
      component={InvoicePayments}
      {...props}
    />
    <PrivateRoute
      exact path={`${props.match.url}/log`}
      permission='memberships.member.log'
      component={Log}
      {...props}
    />
    <PrivateRoute
      exact path={`${props.match.url}/log-beta`}
      permission='memberships.member.log'
      component={LogBeta}
      {...props}
    />
    <PrivateRoute
      path={`${props.match.url}/log/:logID`}
      permission='memberships.member.log'
      component={NoAccessComponent}
      {...props}
    />
    <PrivateRoute
      path={`${props.match.url}/keys`}
      permission='memberships.member.keys'
      component={Keys}
      subComponent
      {...props}
    />
    <PrivateRoute
      path={`${props.match.url}/freeze`}
      permission='memberships.member.freeze'
      component={FreezeList}
      freezePeriods={props.member.freeze_periods}
      subComponent
      {...props}
    />
    <PrivateRoute
      path={`${props.match.url}/referrals`}
      permission='memberships.member.get'
      component={Referrals}
      operator={props.member.operator_id}
      subComponent
      {...props}
    />
    <PrivateRoute
      path={`${props.match.url}/comments`}
      permission='memberships.member.get'
      component={Comments}
      operator={props.member.operator_id}
      subComponent
      {...props}
    />
    <PrivateRoute
      path={`${props.match.url}/no-access`}
      permission='memberships.member.no-access'
      component={NoAccessComponent}
      subComponent
      {...props}
    />
    <PrivateRoute
      path={`${props.match.url}/under-development`}
      permission='customers.customer.update'
      component={UnderDevelopmentComponent}
      subComponent
      {...props}
    />
  </div>

export default MemberRouteAndNavigation
