import React from 'react'
import AppContext from 'AppContext'
import { format, addMonths, endOfMonth } from 'date-fns'

const isFutureDate = dateAsString => {
  return new Date().getTime() < new Date(dateAsString).getTime()
}

const formatDate = date => date ? format(date, 'YYYY-MM-DD') : ''

/*
 * TODO: Would be better placed as part of memberships struct returned from server to not have business logic here.
 */
const nextScheduledFreezePeriodBeginDate = (membership) => {
  return membership.freeze_periods
    .map(fp => fp.begin_date)
    .filter(isFutureDate)
    .sort()[0]
}

/*
 * TODO: Would be better placed as part of memberships struct returned from server to not have business logic here.
 */
const bindingPeriodExpiresOn = (membership) => {
  if (!membership.promotion) return null

  const { promotion: { binding_period_in_months } } = membership
  if (binding_period_in_months) {
    const expiryDate = endOfMonth(addMonths(new Date(membership.begin_date_promotion || membership.begin_date), binding_period_in_months))
    return isFutureDate(expiryDate) ? expiryDate : null
  } else {
    return null
  }
}

const CancelMembership = ({ membership, match }) => {
  let env = 'staging'
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = 'production'
  }
  const token = window.localStorage.getItem('auth-token')
  const { operator: { id } } = React.useContext(AppContext)

  // We may have to wait for AppContext to be available
  if (!id) {
    return (<p>loading...</p>)
  }

  return (
    <div className='box'>
      <cl-cancel-membership
        mountPath={`/operators/${id}/add-ons`}
        apihost={`https://memberships.${env}.credlock.net`}
        membershipid={membership.id}
        membershipstatus={membership.status}
        freezescheduledon={formatDate(nextScheduledFreezePeriodBeginDate(membership))}
        bindingperiodexpireson={formatDate(bindingPeriodExpiresOn(membership))}
        // operatorid="7f937289-19a8-48bc-b11c-65db5b5e780a"
        operatorid={id}
        token={token}
      />
      <p>
        <strong>NOTE:</strong> It might take some time before the member is fully stopped and reflected in the UI
      </p>
    </div>
  )
}

export default CancelMembership
