import React from 'react'
import { Button, HeaderActionButton } from '../../components/Buttons'

const ButtonsDemo = () =>
  <div>
    <br /><hr /><br /><br />
    <Button txt='Sign Up' />
    <span style={{ marginRight: '20px' }} />
    <Button txt='Sign Up' disabled />
    <HeaderActionButton />
    <br /><br />
  </div>

export default ButtonsDemo
