import React from 'react'

const Info = ({ info }) =>
  <div className='info'>
    {info.icon ? <i className={`icon ${info.icon.icon} ${info.icon.color || 'black'}`} /> : ''}
    {info.txt}
  </div>

const TextField = ({ label = 'Label', placeholder = label, meta }) =>
  <div className='form-group'>
    <label className='txt'>
      {label}
      <input className={meta} type='text' placeholder={placeholder} />
    </label>
  </div>

const TextArea = ({ label = 'label', placeholder = label, info }) =>
  <div className='form-group'>
    <label className='txt'>
      {label}
      <textarea placeholder={placeholder} rows='8' cols='50' />
      {info ? <Info info={info} /> : ''}
    </label>
  </div>

export {
  TextField,
  TextArea
}
