import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AppContext from 'AppContext'
import { ActionButton } from 'components/Buttons'
import { DetailBox } from 'components/Boxes'
import FreePassAPI from 'services/api/free_passes'
import { Badge } from 'components/Badges'
import { fullName, KeyActivation } from './components'

const baseUrl = operatorId => `/operators/${operatorId}/free-passes`

const FreePass = ({ match }) => {
  const { id } = match.params
  const { operator: { id: operatorId } } = React.useContext(AppContext)
  const [freePass, setFreePass] = useState(null)

  // added as it seems to help NOT showing a blank page when free pass is not in Mongo yet
  const noOpErrorHandling = () => {}

  useEffect(() => {
    FreePassAPI.fetchFreePass(operatorId, id, setFreePass, noOpErrorHandling)
  }, [id, operatorId])

  if (!freePass) return null

  // Change expired badges from default grey to red
  const cssStatus = freePass.status === 'expired' ? 'stopped' : 'active'

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <span className='breadcrumbs'>
          <Link to={baseUrl(operatorId)}>Free passes</Link>
        </span>
        {fullName(freePass)}
        <span style={{ paddingLeft: '10px' }}>
          <Badge txt={freePass.status.toUpperCase()} className={`status ${cssStatus}`} />
        </span>
      </h1>
      <div className='box-wrapper'>
        <DetailBox
          header='Personlia'
          items={[
            { label: 'Name', value: fullName(freePass) },
            { label: 'Mobile', value: `${freePass.mobile_prefix} ${freePass.mobile_number}` },
            { label: 'E-mail', value: freePass.email },
            { label: 'Company', value: freePass.company },
            { label: 'Role', value: freePass.title },
            { label: 'Description', value: freePass.description }
          ]}
        >
          <ActionButton
            txt='Edit personlia'
            link={`${baseUrl(operatorId)}/${freePass.id}/personlia/edit`}
            icon='edit white'
          />
        </DetailBox>
        <DetailBox
          header='Valid from/to'
          items={[
            { label: 'Begin date', value: freePass.begin_date, type: 'date' },
            { label: 'End date', value: freePass.end_date, type: 'date' },
            { label: 'Code', value: freePass.access_control.keys[0].code, type: 'string' }
          ]}
        >
          <ActionButton
            txt='Edit valid from/to'
            link={`${baseUrl(operatorId)}/${freePass.id}/dates/edit`}
            icon='edit white'
          />
        </DetailBox>
        <DetailBox header='Privileged access?'>
          {freePass.privileged_access ? 'Yes' : 'No'}
          <ActionButton
            txt='Edit priviliged access'
            link={`${baseUrl(operatorId)}/${freePass.id}/priviliged-access/edit`}
            icon='edit white'
          />
        </DetailBox>
        <KeyActivation operatatorid={`${baseUrl(operatorId)}`} ownerid={`${freePass.id}`} />
      </div>
    </div>
  )
}

export default FreePass
