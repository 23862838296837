import React from 'react'
import AppContext from 'AppContext'

const PTListV2 = (props) => {
  // let env = "staging"
  // if (/.*admin.evofitness.*/.test(window.location.origin)) {
  //   env = "production";
  // }

  // const token = window.localStorage.getItem('auth-token')
  const { operator: { id } } = React.useContext(AppContext)

  // We may have to wait for AppContext to be available
  if (!id) {
    return (
      <div className='index pts'>
        <p>waiting for operator...</p>
      </div>
    )
  }

  // Must leave empty h1 there for element below not to be cropped...
  return (
    <div className='index pts'>
      <h1 />
      <cl-pt mountpath={`/operators/${id}/pts`} />
    </div>
  )
}

export default PTListV2
