import React from 'react'
import AppContext from 'AppContext'

const IncompleteSignups = (props) => {
  let env = 'staging'
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = 'production'
  }

  const { operator: { id } } = React.useContext(AppContext)

  // We may have to wait for AppContext to be available
  if (!id) {
    return (
      <div className='index sales-promotions' />
    )
  }

  // Must leave empty h1 there for element below not to be cropped...
  return (
    <div className='index sales-promotions'>
      <h1 />
      <cl-incomplete-signups
        mountpath={`/operators/${id}/memberships/incomplete-signups`}
        apihost={`https://memberships.${env}.credlock.net/api`}
      />
    </div>
  )
}

export default IncompleteSignups
