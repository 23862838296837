import React, { useState } from 'react'
import useReport from './components/helpers/useReport'
import CurrentVsPreviousPeriod from './components/CurrentVsPreviousPeriod'

/* eslint-disable camelcase */
const TrialStatus = ({ location_id = '' }) => {
  const [reportArgs] = useState({ location_id })

  const { data } = useReport('dashboard-in-trials', reportArgs)

  if (!data) return null

  const {
    today,
    percent_converted_last_30_days
  } = data[0]

  const isGood = conversion => conversion >= 50

  return (
    <div className='report-box'>
      <h2>in trial</h2>
      <div className='current-vs-previous-period-list'>
        <CurrentVsPreviousPeriod
          header='Today'
          currentValue={today}
          percentageChange={percent_converted_last_30_days}
          footer='conversion last 30 days'
          isGood={isGood}
        />
      </div>
    </div>
  )
}

export default TrialStatus
