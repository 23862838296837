import React from 'react'
import AppContext from 'AppContext'
import KeySearch from './scenes/List/components/KeySearch'

const MembershipsV2 = (props) => {
  let env = 'staging'
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = 'production'
  }

  const token = window.localStorage.getItem('auth-token')
  const { operator: { id } } = React.useContext(AppContext)

  // We may have to wait for AppContext to be available
  if (!id) {
    return (
      <div className='index members' />
    )
  }

  const currentUser = JSON.parse(localStorage.getItem('currentAdmin')).username

  // Must leave empty h1 there for element below not to be cropped...
  //
  // currentuser required for feature flag only
  return (
    <div className='index members'>
      <h1 />
      <cl-membership-list
        mountpath={`/operators/${id}/memberships`}
        currentuser={currentUser}
        apihost={`https://memberships.${env}.credlock.net`}
        operatorid={id}
        token={token}
      />
      <KeySearch
        operatatorid={id}
      />
    </div>
  )
}

export default MembershipsV2
