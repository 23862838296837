import React from 'react'

const Dashboard = () => {
  const token = window.localStorage.getItem('auth-token')
  const api = window.localStorage.getItem('reports-api')
  const root = window.localStorage.getItem('reports-root')

  return (
    <div className='index'>
      <h1>Dashboard</h1>
      <reports-dashboard api={api} root={root} token={token} />
    </div>
  )
}

export default Dashboard

export {
  Dashboard
}
