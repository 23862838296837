import React from 'react'
import { Link } from 'react-router-dom'
import { ActionButton } from 'components/Buttons'
import { DetailBox } from 'components/Boxes'
import AppContext from 'AppContext'

const PromotionDetails = ({ promotion }) => {
  const { locations } = React.useContext(AppContext)

  const triggerCode = promotion.promotion_type === 'referral'
    ? 'Membership referral code'
    : promotion.code

  const items = [
    { label: 'Trigger code', value: triggerCode }
  ]

  if (promotion.trigger_location_ids && locations) {
    const triggerLocations = promotion.trigger_location_ids.length
      ? locations
          .filter(l => promotion.trigger_location_ids.includes(l.id))
          .map(l =>
            <Link
              key={l.id}
              to={{
                pathname: `/operators/${promotion.operator_id}/locations/${l.id}`,
                state: { obj: l }
              }}
            >
              {l.name}
              <br />
            </Link>
          )
      : 'none'

    items.push({ label: 'Trigger locations', value: triggerLocations })
  }

  return (
    <DetailBox header='Promotion triggers' items={items}>
      <ActionButton
        txt='Edit triggers'
        icon='edit white'
        link={`/operators/${promotion.operator_id}/promotions/${promotion.id}/trigger-locations/edit`}
      />
    </DetailBox>
  )
}

export default PromotionDetails
