import React from 'react'
import { dateToLocale } from '../Helpers'

const Name = ({ first_name: firstName, last_name: lastName }) =>
  firstName + ' ' + lastName

const Mobile = ({ mobile }) =>
  mobile ? mobile.prefix + ' ' + mobile.number : ''

const PostalCodeAndLocation = ({ address }) => {
  if (!address) return 'N/A'

  return (
    `${address.postal_code} ${address.postal_location}`
  )
}

const Address = ({ address }) => {
  if (!address) return 'N/A'

  return (
    `${address.street}, ${address.postal_code} ${address.postal_location}`
  )
}

const Row = ({ label, value, demo, type, visible = true }) => {
  if (!visible) return null

  let myValue

  switch (type) {
    case 'mobile':
      myValue = <Mobile mobile={value} />
      break
    case 'name':
      myValue = <Name {...value} />
      break
    case 'date':
      myValue = dateToLocale(value)
      break
    case 'postal':
      myValue = <PostalCodeAndLocation address={value} />
      break
    case 'address':
      myValue = <Address address={value} />
      break
    case 'money':
      myValue = <cl-money amount={value} />
      break
    default:
      myValue = value
  }

  return (
    <tr>
      <th>{label}:</th>
      <td style={demo ? { color: 'grey' } : {}}>{myValue}</td>
    </tr>
  )
}

const Table = ({ items }) => {
  if (!items) return null

  return (
    <table>
      <tbody>
        {items.map(item => <Row key={item.label} {...item} />)}
      </tbody>
    </table>
  )
}

const DetailBox = ({ header, items, children, style }) =>
  <div className='box' style={style}>
    <h2>{header}</h2>
    <Table items={items} />
    {children}
  </div>

export default DetailBox
