import React from 'react'
import Table from 'components/Table'
import { dateToLocale } from 'components/Helpers'
import Period from './components/Period'
import Amount from 'components/Helpers/Amount'

const ScheduledInvoiceListView = ({ invoices }) => {
  return (
    <div className='index'>
      <h2 className='with-response-margin'>
        Scheduled invoices ({invoices.length})
      </h2>
      <div className='content-box invoices'>
        <Table
          className='invoice-list'
          data={invoices}
          defaultPageSize={12}
          columns={[
            {
              Header: 'Invoice date',
              accessor: 'invoice_date',
              width: 120,
              Cell: props =>
                dateToLocale(props.original.invoice_date)
            },
            {
              Header: 'Amount',
              accessor: 'amount',
              width: 110,
              Cell: props =>
                <Amount
                  amount={props.original.amount}
                  currency={props.original.currency}
                />
            },
            {
              Header: 'Period',
              accessor: 'period',
              Cell: props =>
                <Period period={props.original.period} />
            }
          ]}
          defaultSorted={[{ id: 'invoice_date' }]}
        />
      </div>
    </div>
  )
}

export default ScheduledInvoiceListView
