import React, { useState } from 'react'
import useReport from './components/helpers/useReport'

/* eslint-disable camelcase */
const ActivationsByCategory = ({ location_id = '' }) => {
  const [reportArgs] = useState({ location_id })

  const { data, meta, totals } = useReport('activations-by-category', reportArgs)

  if (!data) return null

  return (
    <div className='report-wrapper with-padding'>
      <header>
        <h2>Activations today vs yesterday & this month vs last year</h2>
      </header>
      <table>
        <thead>
          <tr>
            {meta.map(column => <th key={column.name} style={{ textTransform: 'uppercase' }}>{column.name}</th>)}
          </tr>
        </thead>
        <tbody>
          {data.map((activation, i) => {
            return (
              <tr key={i}>
                <td>{activation.activation_type}</td>
                <td>{activation.today}</td>
                <td>{activation.current_month}</td>
                <td>{activation.yesterday}</td>
                <td>{activation.same_month_last_year_to_same_time}</td>
              </tr>
            )
          })}
          <tr className='totals'>
            <td />
            <td>{totals.today}</td>
            <td>{totals.current_month}</td>
            <td>{totals.yesterday}</td>
            <td>{totals.same_month_last_year_to_same_time}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ActivationsByCategory
