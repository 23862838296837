import React from 'react'
import AppContext from 'AppContext'

const Comments = (props) => {
  let env = 'staging'
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = 'production'
  }

  const token = window.localStorage.getItem('auth-token')
  const { operator: { id: operatorId } } = React.useContext(AppContext)
  const { member: { id: membershipId } } = props

  // We may have to wait for AppContext to be available
  if (!operatorId) {
    return (
      <div className='index sales-promotions' />
    )
  }

  // Must leave empty h1 there for element below not to be cropped...
  return (
    <div className='index sales-promotions'>
      <h1 />
      <cl-comments
        membershipid={membershipId}
        mountpath={`/operators/${operatorId}/memberships`}
        apihost={`https://memberships.${env}.credlock.net`}
        operatorid={operatorId}
        token={token}
      />
    </div>
  )
}

export default Comments
