import React from 'react'

const onGenderChange = (ev, filtered) => {
  const gender = ev.target.value

  const previousStatusFilter = filtered.find(f => f.id === 'genders')

  const statuses = previousStatusFilter ? previousStatusFilter.value : []

  const newValue =
    statuses.includes(gender)
      ? statuses.filter(s => s !== gender)
      : statuses.concat(gender)

  return filtered
    .filter(f => f.id !== 'genders')
    .concat({ id: 'genders', value: newValue })
}

const GenderFilter = ({ onChange, filtered }) => {
  const filter = filtered ? filtered.find(f => f.id === 'genders') : ['FEMALE', 'MALE']

  return (
    <select
      style={{ width: '100%' }}
      name='genders'
      multiple
      onChange={(ev) => onChange(onGenderChange(ev, filtered))}
      defaultValue={filter ? filter.value : ['FEMALE', 'MALE']}
    >
      {
        ['FEMALE', 'MALE'].map(s =>
          <option key={s} value={s}>{s}</option>
        )
      }
    </select>
  )
}

export default GenderFilter
