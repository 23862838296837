import React, { useState, useEffect } from 'react'
import AdminAPI from 'services/api/admins'
import { Redirect } from 'react-router-dom'
import {
  viewAdminUrl,
  Breadcrumbs
} from '../components'
import {
  Form,
  FormGroup,
  FormButtons,
  CheckboxInput,
  Submit,
  humanize
} from 'components/Forms'

const EditRoles = ({ match }) => {
  const { id, operatorID: operatorId } = match.params
  const [updatedId, setUpdatedId] = useState(null)
  const [admin, setAdmin] = useState(null)
  const [availableRoles, setAvailableRoles] = useState([])
  const [validationErrors, setValidationErrors] = useState({})

  useEffect(() => {
    AdminAPI.fetchAdmin(operatorId, id, setAdmin)
  }, [id, operatorId])

  useEffect(() => {
    AdminAPI.fetchRoles(
      operatorId,
      successResult => setAvailableRoles(successResult.data),
      errorResult => alert(errorResult) // TODO display error message
    )
  }, [operatorId])

  if (!admin || !availableRoles) return null

  const onSubmit = data => {
    AdminAPI.update(
      { ...data, roles: data.roles.filter(Boolean), part: 'roles', id },
      successfulResult => setUpdatedId(successfulResult.id),
      errorResult => setValidationErrors(errorResult)
    )
  }

  if (updatedId) return <Redirect to={viewAdminUrl(updatedId, operatorId)} />

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <Breadcrumbs operatorId={operatorId} admin={admin} />
        Edit roles
      </h1>
      <div className='form'>
        <Form onSubmit={onSubmit} defaultValues={admin} validationErrors={validationErrors}>
          <FormGroup header='Roles'>
            {availableRoles.map((value, index) => {
              return (
                <CheckboxInput
                  name={`roles[${index}]`}
                  value={value.id}
                  label={humanize(value.name)}
                  checked={admin.roles.includes(value.name)}
                />
              )
            })}
          </FormGroup>
          <FormButtons>
            <Submit label='Update roles' />
          </FormButtons>
        </Form>
      </div>
    </div>
  )
}

export default EditRoles
