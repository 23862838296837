import React, { Component } from 'react'
import InvoiceAPI from 'services/api/invoices'
import { extractInvoiceIDFromURL } from './components/Helpers'
import InvoiceView from './InvoiceView'

class Invoice extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: true,
      invoice: null,
      memberStatus: props.member.status
    }
  }

  componentDidMount () {
    const { operatorID, memberID } = this.props.match.params
    const invoiceID = extractInvoiceIDFromURL(this.props.location)
    InvoiceAPI.fetchInvoice(operatorID, memberID, invoiceID, (res) => {
      this.setState({ invoice: res, loading: false })
    })
  }

  render () {
    return <InvoiceView {...this.state} />
  }
}

export default Invoice
