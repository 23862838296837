import PromotionsV2 from './PromotionsV2'
import PromotionLists from './list'
import Promotion from './view/'
import { NewSalesPromotion } from './create'
import {
  EditDetails,
  EditSalesPeriod,
  EditPresentation,
  EditTrialDays,
  EditPTHours,
  EditSignupFee,
  EditPaymentPlan,
  EditTriggerLocations,
  EditReferralRewards
} from './update'

export {
  PromotionsV2,
  Promotion,
  NewSalesPromotion,
  EditDetails as EditPromotionDetails,
  EditSalesPeriod as EditPromotionSalesPeriod,
  EditPresentation as EditPromotionPresentation,
  EditTrialDays as EditPromotionTrialDays,
  EditPTHours as EditPromotionPTHours,
  EditSignupFee as EditPromotionSignupFee,
  EditPaymentPlan as EditPromotionPaymentPlan,
  EditTriggerLocations as EditPromotionTriggerLocations,
  EditReferralRewards as EditPromotionReferralRewards
}

export default PromotionLists
