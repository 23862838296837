import React, { Component } from 'react'

import { ActionButton } from 'components/Buttons/index'

import MarkPaidModal from '../MarkPaidModal'

class MarkPaidButton extends Component {
  constructor (props) {
    super(props)

    this.state = {
      paidModalIsOpen: false
    }

    this.openPaidModal = this.openPaidModal.bind(this)
    this.closePaidModal = this.closePaidModal.bind(this)
  }

  openPaidModal () {
    this.setState({ paidModalIsOpen: true })
  }

  closePaidModal () {
    this.setState({ paidModalIsOpen: false })
  }

  render () {
    if (this.props.invoice.status !== 'declined') {
      return null
    }
    if (this.state.paidModalIsOpen) {
      return (
        <MarkPaidModal
          invoice={this.props.invoice}
          onClose={this.closePaidModal}
        />
      )
    } else {
      return (
        <ActionButton
          txt='Mark Paid'
          onClick={this.openPaidModal}
          icon='error #FFA500'
        />
      )
    }
  }
}

export default MarkPaidButton
