import React from 'react'
import AppContext from 'AppContext'

const ProductV2 = ({ match }) => {
  let env = 'staging'
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = 'production'
  }
  const token = window.localStorage.getItem('auth-token')
  const { operator: { id, currency } } = React.useContext(AppContext)

  // We may have to wait for AppContext to be available
  if (!id) {
    return (
      <div className='index sales-promotions'>
        <h1>&nbsp;</h1>
        <p>waiting for operator...</p>
      </div>
    )
  }

  // Must leave empty h1 there for element below not to be cropped...
  return (
    <div className='index sales-promotions'>
      {/* eslint-disable-next-line */}
      <h1></h1>
      <cl-product
        mountPath={`/operators/${id}/product`}
        apihost={`https://memberships.${env}.credlock.net`}
        operatorid={id}
        token={token}
        currency={currency}
      />
    </div>
  )
}

export default ProductV2
