/*
 * Styles Chart.js datasets (currently line charts only).
 *
 * Uses 3 custom keys in Chart.js datasets (added on the top level):
 * lineColor, fillGradientStartColor and fillGradientStopColor
 */
const styleChart = (chartData, canvasRef) => {
  if (chartData.type === 'line') {
    const ctx = canvasRef.current.getContext('2d')

    const styledDatasets = chartData.data.datasets.map(dataset => {
      const gradient = ctx.createLinearGradient(0, 0, 0, canvasRef.current.height)
      gradient.addColorStop(0, dataset.fillGradientStartColor)
      gradient.addColorStop(1, dataset.fillGradientStopColor)

      return Object.assign({}, {
        ...dataset,
        backgroundColor: gradient,
        borderColor: dataset.lineColor,
        borderWidth: 3,
        pointBorderColor: dataset.lineColor,
        pointBackgroundColor: dataset.lineColor,
        pointHoverBackgroundColor: dataset.lineColor,
        pointHoverBorderColor: dataset.lineColor
      })
    })

    chartData.data.datasets = styledDatasets
  }

  return chartData
}

export default styleChart
