import React, { useState, useEffect } from 'react'
import PT_API from 'services/api/pts'
import { viewPTUrl, Breadcrumbs } from './components'
import AppContext from 'AppContext'
import {
  Form,
  FormLine,
  DateInput,
  Submit,
  TextInput,
  Switch,
  FormButtons,
  MoneyInput
} from 'components/Forms'

import { toast } from 'react-toastify'

const EditValidFromAndToDates = ({ match, history }) => {
  const { operator: { id: operatorId, currency } } = React.useContext(AppContext)
  const { id } = match.params
  const [pt, setFreePass] = useState(null)
  const [validationErrors, setValidationErrors] = useState({})

  useEffect(() => {
    PT_API.fetchPT(operatorId, id, setFreePass)
  }, [id, operatorId])

  if (!pt) return null

  const onSubmit = (data) => {
    PT_API.update(
      { ...data, id },
      id,
      (successfulResult) => {
        console.log('pt updated')
        toast.success('Changes saved')
        history.push(viewPTUrl(id, operatorId))
      },
      (errorResult) => {
        console.error('failed to update', errorResult)
        toast.error(errorResult)
        setValidationErrors(errorResult)
      }
    )
  }

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <Breadcrumbs operatorId={operatorId} pt={pt} />
        Edit PT contract
      </h1>
      <div className='content-box form'>
        <Form
          onSubmit={onSubmit}
          defaultValues={pt}
          validationErrors={validationErrors}
        >
          <FormLine>
            <DateInput name='begin_date' required='true' />
            <DateInput name='end_date' />
          </FormLine>
          <FormLine>
            <TextInput name='company_name' />
            <TextInput name='company_registration_number' />
          </FormLine>
          <FormLine>
            <MoneyInput
              label='Monthly fee'
              amount={pt.monthly_fee}
              name='monthly_fee'
              currency={currency}
            />
            <DateInput name='fee_payable_from_date' />
          </FormLine>
          <Switch label='Show PT on web site?' name='publish' />
          <FormButtons>
            <Submit label='Update' />
          </FormButtons>
        </Form>
      </div>
    </div>
  )
}

export default EditValidFromAndToDates
