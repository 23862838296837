import Chart from 'chart.js'
import { merge } from 'lodash'

/*
 * Defines both global Chart.js options and options that applies to
 * a chart type only. This configuration file MUST be required at least
 * once.
 *
 * Chart.js will subsequently merge global and shared chart options with options
 * supplied to the Chart constructor.
 *
 * Configuration of shared options is done with lodash.merge to avoid
 * loosing options nested in Arrays/Objects that are not specified.
 *
 * Configuration file works with both bare bones Chart.js and using the react wrapper lib.
 *
 * TODO: merge function does not work as expected. Only modifying
 * one tick key for an axis on line chart leads to config being
 * missed
 */
Chart.defaults.global = merge({}, Chart.defaults.global, {
  defaultFontFamily: 'MarkPro-Light, sans-serif',
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  layout: {
    padding: {
      top: 70,
      left: 20,
      right: 20,
      bottom: 20
    }
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          drawBorder: false,
          display: true,
          color: 'rgba(142, 142, 142, 0.9)',
          borderDash: [1, 10]
        },
        ticks: {
          beginAtZero: true,
          fontSize: 12,
          padding: 20,
          fontColor: 'rgba(142, 142, 142, 0.6)'
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          drawOnChartArea: false,
          color: 'rgba(142, 142, 142, 0.9)'
        },
        ticks: {
          fontSize: 12,
          padding: 10,
          fontColor: '#404040'
        }
      }
    ]
  }
})

Chart.defaults.bar = merge({}, Chart.defaults.global, Chart.defaults.bar, {
  scales: {
    xAxes: [{
      offset: true,
      stacked: true,
      gridLines: {
        display: false
      },
      ticks: {
        fontColor: '#404040'
      }
    }],
    yAxes: [{
      stacked: true,
      ticks: {
        fontColor: '#404040'
      }
    }]
  }
})

Chart.defaults.line = merge({}, Chart.defaults.global, Chart.defaults.line, {
  scales: {
    xAxes: [{
      offset: false,
      ticks: {
        fontColor: '#404040'
      }
    }],
    yAxes: [{
      ticks: {
        fontColor: '#404040'
      }
    }]
  }
})
