import React, { Component } from 'react'

import { ActionButton } from 'components/Buttons/index'

import StopModal from '../StopModal'

class StopButton extends Component {
  constructor (props) {
    super(props)

    this.state = {
      modalIsOpen: false,
      stopped: !!this.props.invoice.stopped,
      active: this.props.status !== 'stopped' && !this.props.status?.match(/^cancelled/)
    }

    this.openStopModal = this.openStopModal.bind(this)
    this.closeStopModal = this.closeStopModal.bind(this)
  }

  openStopModal () {
    this.setState({ stopModalIsOpen: true })
  }

  closeStopModal () {
    this.setState({ stopModalIsOpen: false })
  }

  render () {
    if (this.props.invoice.status !== 'declined' || !this.props.invoice.retry) {
      console.log('stop button - not declined')
      return null
    }
    // only allow stopping on stopped/cancelled memberships where invoice is not stopped
    if (this.state.stopped && !this.state.active) {
      console.log('stop button - stopped and not active', this.props.status, this.state.active)
      return null
    }
    // only allow unstopping on active memberships where invoice is stopped
    if (!this.state.stopped && this.state.active) {
      console.log('stop button - is stopped and is active')
      return null
    }

    if (this.state.stopModalIsOpen) {
      return (
        <StopModal
          invoice={this.props.invoice}
          onClose={this.closeStopModal}
          active={this.state.active}
          stopped={this.state.stopped}
        />
      )
    } else {
      return (
        <ActionButton
          txt={this.state.stopped ? 'Enable Retry' : 'Stop Retry'}
          onClick={this.openStopModal}
          icon='error #FFA500'
        />
      )
    }
  }
}

export default StopButton
