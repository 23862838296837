import React from 'react'

import { useFormContext } from 'react-hook-form'

const Select = ({ children, ...rest }) => {
  const { register } = useFormContext()

  return (
    <select ref={register()} {...rest}>{children}</select>
  )
}

export default Select
