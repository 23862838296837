import React from 'react'
import Select from 'react-select'
import API from 'services/api/api'

const Regions = ({ onFilterChange }) => {
  const [regions, setRegions] = React.useState(null)

  React.useEffect(() => {
    API.fetch('regions', (res) => {
      setRegions(res.data)
    })
  }, [])

  if (!regions) return null

  const onChange = (ev) => {
    if (!ev || ev.length < 1) { return onFilterChange() }

    return onFilterChange({ regions: ev.map(e => e.value) })
  }

  return (
    <div>
      <h3>By region</h3>
      <Select
        isMulti
        options={regions.map(r => ({ value: r.id, label: r.name }))}
        placeholder='By region(s)'
        onChange={onChange}
      />
    </div>
  )
}

export default Regions
