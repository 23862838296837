import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import AppContext from 'AppContext'
import PT_API from 'services/api/pts'
import locationAPI from 'services/api/locations'
import { Redirect } from 'react-router-dom'
import {
  Breadcrumbs,
  LocationsWithRole
} from './components'
import {
  Form,
  FormLine,
  TextInput,
  DateInput,
  EmailInput,
  Switch,
  Submit,
  FormGroup,
  FormButtons,
  Mobile,
  MoneyInput
} from 'components/Forms'

const NewPT = () => {
  const { operator: { id: operatorId, currency } } = React.useContext(AppContext)
  const [newPTId, setNewPTId] = useState(null)
  const [validationErrors, setValidationErrors] = useState({})

  const [availableRoles, setAvailableRoles] = useState([])
  useEffect(() => {
    PT_API.fetchRoles(
      operatorId,
      successfulResult => setAvailableRoles(successfulResult.data)
    )
  }, [operatorId])

  const [locations, setLocations] = useState([])

  const [availableLocations, setAvailbleLocations] = useState([])
  useEffect(() => {
    locationAPI.fetchLocations(
      operatorId,
      {},
      successfulResult => setAvailbleLocations(successfulResult.data)
    )
  }, [operatorId])

  const onSubmit = data => {
    PT_API.create(
      { ...data, roles: locations, source: 'admin-ui' },
      operatorId,
      successfulResult => {
        toast.success('PT created')
        setNewPTId(successfulResult.id)
      },
      errorResult => setValidationErrors(errorResult)
    )
  }

  if (newPTId) return <Redirect to={viewPTList(operatorId)} />

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <Breadcrumbs operatorId={operatorId} />
        New PT
      </h1>
      <div className='form'>
        <Form onSubmit={onSubmit} defaultValues={{}} validationErrors={validationErrors}>
          <FormGroup header='Personalia'>
            <FormLine>
              <TextInput name='first_name' required />
              <TextInput name='last_name' required />
            </FormLine>
            <Mobile focus={false} operator={operatorId} required />
            <EmailInput name='email' required />
            <TextInput name='address_street' label='Street' size='large' />
            <FormLine>
              <TextInput name='address_postal_code' key='postalCode' label='Postal code' size='small' />
              <TextInput name='address_postal_location' key='postalLocation' label='Postal location' />
            </FormLine>
            <DateInput name='birthdate' />
          </FormGroup>
          <FormGroup header='Locations'>
            <LocationsWithRole
              locationsWithRole={locations}
              setLocationsWithRole={setLocations}
              availableLocations={availableLocations}
              availableRoles={availableRoles}
            />
          </FormGroup>
          <FormGroup header='Contract'>
            <FormLine>
              <DateInput name='begin_date' required />
              <DateInput name='end_date' />
            </FormLine>
            <FormLine>
              <TextInput name='company_name' />
              <TextInput name='company_registration_number' />
            </FormLine>
            <FormLine>
              <MoneyInput
                label='Monthly Fee'
                name='monthly_fee'
                currency={currency}
              />
              <DateInput name='fee_payable_from_date' />
            </FormLine>
            <Switch
              label='Show PT on web site?'
              name='publish'
            />
          </FormGroup>
          <FormButtons>
            <Submit label='Add PT' />
          </FormButtons>
        </Form>
      </div>
    </div>
  )
}

const viewPTList = (operatorId) => `/operators/${operatorId}/pts/`

export default NewPT
