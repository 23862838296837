import React, { forwardRef, useRef, useEffect } from 'react'

const SyncUserKeys = forwardRef((props, ref) => {
  let env = 'staging'
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = 'production'
  }
  const { operatorid, membershipid } = props;
  const token = window.localStorage.getItem('auth-token');
  const syncRef = useRef();

  useEffect(() => {
    if (ref && syncRef.current) {
      if (typeof ref === 'function') {
        ref(syncRef.current)
      } else {
        ref.current = syncRef.current;
      }
    }
  }, [ref]);

  return (
    <div className='content-box synckeys'>
      <cl-sync-user-keys
        ref={syncRef}
        mountPath={`/operators/${operatorid}/members`}
        apiUrl={`https://access-control.${env}.credlock.net/api`}
        token={token}
        ownerid={membershipid}
      />
    </div>
  )
});

export default SyncUserKeys
