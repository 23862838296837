import React from 'react'
import MonthlyPaymentScheduleEntry from './MonthlyPaymentScheduleEntry'

const MonthlyPaymentSchedule = () => {
  const months = [
    { begin_month_index: 0, end_month_index: 0 },
    { begin_month_index: 1, end_month_index: 1 },
    { begin_month_index: 2, end_month_index: 2 },
    { begin_month_index: 3, end_month_index: 3 },
    { begin_month_index: 4, end_month_index: 4 },
    { begin_month_index: 5, end_month_index: 5 },
    { begin_month_index: 6, end_month_index: 6 },
    { begin_month_index: 7, end_month_index: 7 },
    { begin_month_index: 8, end_month_index: 8 },
    { begin_month_index: 9, end_month_index: 9 },
    { begin_month_index: 10, end_month_index: 10 },
    { begin_month_index: 11, end_month_index: 11 },
    { begin_month_index: 12, end_month_index: 12 },
    { begin_month_index: 13, end_month_index: 24 },
    { begin_month_index: 25, end_month_index: 36 },
    { begin_month_index: 37 }
  ]

  return (
    <ol style={{ listStyle: 'none', padding: 0 }}>
      {months.map((month, index) => {
        return (
          <li key={month.begin_month_index}>
            <MonthlyPaymentScheduleEntry month={month} index={index} />
          </li>
        )
      })}
    </ol>
  )
}

export default MonthlyPaymentSchedule
