import React from 'react'
import { Link } from 'react-router-dom'
import Icon from 'components/Icons'

// We provide icon as e.g 'edit white'
const CSSClassToIcon = ({ icon }) => {
  if (!icon) {
    return ''
  }

  const [type, color] = icon.split(' ')

  return (
    <Icon type={type} color={color} />
  )
}

const JustButton = ({ txt, disabled, icon, className = '', onClick }) =>
  <button
    onClick={onClick}
    className={'btn ' + className}
    disabled={disabled}
  >
    {txt}
    <CSSClassToIcon icon={icon} />
  </button>

const ButtonWithLink = (props) =>
  <Link to={{ pathname: props.link, state: props.state }}>
    <JustButton {...props} />
  </Link>

const Button = (props) =>
  props.link ? <ButtonWithLink {...props} /> : <JustButton {...props} />

const ActionButton = (props) =>
  <HeaderActionButton {...props} />

const HeaderActionButton = (props) =>
  <span className={'action-button ' + props.className}>
    <Button {...props} />
  </span>

const FilterButton = ({ setActive, active }) => {
  return (
    <div
      className='filter'
      onClick={() => setActive(!active)}
    >
      <div className='filter button'>
        <ActionButton
          className={active ? 'full-width' : 'just-border full-width'}
          txt='Filters'
          icon={`filter ${active ? 'white' : '#4727AA'}`}
        />
      </div>
    </div>
  )
}

export {
  Button,
  ActionButton,
  HeaderActionButton,
  FilterButton
}
