import React, { Component } from 'react'
import StopModalView from './StopModalView'
import ActionSuccessView from './ActionSuccessView'
import ActionErrorView from './ActionErrorView'
import { stopInvoice } from 'services/api/invoices'

class StopModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      stopped: this.props.stopped,
      status: null,
      error: null
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  handleSubmit (ev) {
    ev.preventDefault()

    const args = {
      reason: this.state.reasonToStop
    }

    // console.log('refund args', args)

    stopInvoice(
      args,
      this.props.invoice.membership_id,
      this.props.invoice.id,
      this.props.stopped ? 'unstop' : 'stop',
      (res) => {
        console.log(res)
        if (res.status === 'accepted') {
          this.setState({ status: 'success' })
        } else {
          this.setState({ status: 'error', error: 'Stop failed' })
        }
      },
      (err) => {
        console.log(err)
        this.setState({ status: 'error' })
      }
    )
  }

  render () {
    const { status } = this.state

    if (status === 'success') {
      return (
        <ActionSuccessView
          action={this.props.stopped ? 'Enable' : 'Stop'}
          onClose={this.props.onClose}
        />
      )
    } else if (status === 'error') {
      return (
        <ActionErrorView
          action={this.props.stopped ? 'Enable' : 'Stop'}
          onClose={this.props.onClose}
          error={this.state.error}
        />
      )
    } else {
      return (
        <StopModalView
          invoice={this.props.invoice}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          onClose={this.props.onClose}
          {...this.state}
        />
      )
    }
  }
}

export default StopModal
