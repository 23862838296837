import { format } from 'date-fns'

// Returns e.g. Apr 19
const formatYearMonth = yearmonth => {
  const year = yearmonth.toString().substring(0, 4)
  const month = yearmonth.toString().substring(4, 6)
  const day = '01'
  const date = new Date(`${year}-${month}-${day}`)

  const pattern = 'MMM YY'

  return format(date, pattern)
}

export default formatYearMonth
