import React, { Component } from 'react'
import PromotionsAPI from 'services/api/promotions'
import ReferralPromotionListView from './ReferralPromotionListView'
import CurrentReferral from './components/CurrentReferral'
import withPromotions from '../withPromotions'
import promotionUrl from '../components/promotionUrl'

const sort = [
  { field: 'sales_period_begins_at', order: 'desc' }
]

class ReferralPromotionListWrapper extends Component {
  constructor (props) {
    super(props)

    this.state = {
      currentPromotion: null
    }
  }

  fetchCurrentPromotion () {
    const args = {
      filter: {
        types: ['referral'],
        status: 'active'
      }
    }

    const operatorID = this.props.match.params.operatorID

    PromotionsAPI.search(operatorID, args, (res) =>
      this.setState({ currentPromotion: res.data[0] })
    )
  }

  componentDidMount () {
    this.fetchCurrentPromotion()
  }

  render () {
    return (
      <span>
        <CurrentReferral
          promotion={this.state.currentPromotion}
          baseURL={promotionUrl(this.props.match)}
        />
        <div className='content-box promotions'>
          <h2
            style={{
              fontSize: '16px',
              paddingLeft: '20px',
              paddingTop: '20px',
              paddingBottom: '20px'
            }}
          >
            PAST REFERRAL PROMOTIONS
          </h2>
          <ReferralPromotionListView {...this.props} />
        </div>
      </span>
    )
  }
}

const columns = [
  'sales_period',
  'status',
  'name',
  'referrer_rewards',
  'pt_hours',
  'signup_fee',
  'monthly_payment_schedule',
  'created_by.name',
  'updated_by.name',
  'trial_days'
]

// We don't need any filters for referral promotions?
const filters = false

export default withPromotions(
  ReferralPromotionListWrapper,
  { types: ['referral'], status: 'expired' },
  columns,
  filters,
  sort
)
