import React, { useState } from 'react'
import AppContext from 'AppContext'
import PaidPassAPI from 'services/api/paid-passes'
import { Redirect } from 'react-router-dom'

import {
  Form,
  TextInput,
  DateInput,
  Submit
} from 'components/Forms'

import ErrorMessage from 'components/Forms/components/ErrorMessage'
import Lifetime from './components/Lifetime'
import PaidPassBreadCrumbs from './components/PaidPassBreadCrumbs'

const url = (operatorId, id) => {
  return `/operators/${operatorId}/paid-passes/distributors/${id}`
}

const PaidPassesGenerate = ({ match, location }) => {
  const [created, setCreated] = useState(null)
  const { distributorId } = match.params
  const { operator: { id: operatorId } } = React.useContext(AppContext)
  const [validationErrors, setValidationErrors] = useState({})

  // Getting name from the location.state if present
  const { state: { obj: { name: distributorName } } = { obj: {} } } = location

  const onSubmit = data => {
    delete data._lifetime_amount
    delete data._lifetime_scale
    PaidPassAPI.paidPasses.create(
      { ...data, distributor_id: distributorId, source: 'admin-ui' },
      operatorId,
      successfulResult => setCreated(true),
      errorResult => setValidationErrors(errorResult)
    )
  }

  if (created) return <Redirect to={url(operatorId, distributorId)} />

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <PaidPassBreadCrumbs
          operatorId={operatorId}
          list={[
            { path: url(operatorId, distributorId), text: distributorName }
          ]}
        />
        Generate paid passes
      </h1>
      <div className='content-box form'>
        <Form onSubmit={onSubmit} defaultValues={{}} validationErrors={validationErrors.errors}>
          <TextInput focus name='amount' />
          <Lifetime focus={false} name='lifetime' />
          <DateInput name='valid_from' />
          <DateInput name='valid_to' />
          <Submit label='Generate' />
          <ErrorMessage name='message' errors={validationErrors} />
        </Form>
      </div>
    </div>
  )
}

export default PaidPassesGenerate
