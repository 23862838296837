import React, { useState, useEffect } from 'react'
import PromotionsAPI from 'services/api/promotions'
import { Redirect } from 'react-router-dom'
import {
  viewPromotionUrl,
  Breadcrumbs
} from './components'
import {
  Form,
  Submit
} from 'components/Forms'
import { MonthlyPaymentSchedule } from '../components'
import {
  sanitizePaymentSchedule,
  promotionWithDecimalAmounts
} from '../components/paymentSchedule'

const EditPaymentPlan = ({ match }) => {
  const { promotionID: id, operatorID: operatorId } = match.params
  const [promotion, setPromotion] = useState(null)
  const [isUpdated, setIsUpdated] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})

  useEffect(() =>
    PromotionsAPI.get(operatorId, id, setPromotion)
  , [id, operatorId])

  if (!promotion) return null

  const onSubmit = data => {
    PromotionsAPI.update(
      {
        type: promotion.promotion_type,
        part: 'monthly_payment_schedule',
        id,
        ...data,
        monthly_payment_schedule: sanitizePaymentSchedule(data.monthly_payment_schedule)
      },
      _successfulResult => setIsUpdated(true),
      errorResult => setValidationErrors(errorResult)
    )
  }

  if (isUpdated) return <Redirect to={viewPromotionUrl(id, operatorId)} />

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <Breadcrumbs operatorId={operatorId} promotion={promotion} />
        Edit payment plan
      </h1>
      <div className='content-box form'>
        <Form
          onSubmit={onSubmit}
          defaultValues={promotionWithDecimalAmounts(promotion)}
          validationErrors={validationErrors}
        >
          <MonthlyPaymentSchedule />
          <Submit label='Update' />
        </Form>
      </div>
    </div>
  )
}

export default EditPaymentPlan
