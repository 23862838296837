import React from 'react'
import Select from 'react-select'
import { capitalizeFirstLetter } from 'components/Helpers'

const allStatuses = [
  'presale',
  'cancelled_in_presale',
  'active',
  'in_freeze',
  'in_cancellation',
  'cancelled',
  'stopped',
  'cancelled_in_trial',
  'in_trial'
]

const onStatusChange = (val, filtered) => {
  const status = val.target.value

  if (status === 'all') {
    return filtered.filter(f => f.id !== 'statuses')
  }

  const previousStatusFilter = filtered.find(f => f.id === 'statuses')

  const statuses = previousStatusFilter ? previousStatusFilter.value : []

  const newValue =
    statuses.includes(status)
      ? statuses.filter(s => s !== status)
      : statuses.concat(status)

  return filtered
    .filter(f => f.id !== 'statuses')
    .concat({ id: 'statuses', value: newValue })
}

const StatusSelector = ({ onChange, filtered }) => {
  const filteredStatuses = filtered.find(f => f.id === 'statuses')

  const onStatusChange = (ev) => {
    if (!ev || ev.length < 1) { return filtered.filter(f => f.id !== 'statuses') }

    return filtered
      .filter(f => f.id !== 'statuses')
      .concat(({ id: 'statuses', value: ev.map(e => e.value) }))
  }

  const option = (status) => ({
    value: status,
    label: capitalizeFirstLetter(status.split('_').join(' '))
  })

  const options = allStatuses
    .map(option)

  const value = filteredStatuses
    ? filteredStatuses.value.map(option)
    : []

  return (
    <Select
      isMulti
      options={options}
      value={value}
      onChange={(ev) => onChange(onStatusChange(ev))}
    />
  )
}

const StatusFilter = ({ onChange, filtered }) => {
  const filter = filtered ? filtered.find(f => f.id === 'statuses') : ['active']

  return (
    <select
      name='statuses'
      multiple
      onChange={(ev) => onChange(onStatusChange(ev, filtered))}
      defaultValue={filter ? filter.value : ['all']}
    >
      <option value='all'>All</option>
      {
        allStatuses.map(s =>
          <option key={s} value={s}>{s}</option>
        )
      }
    </select>
  )
}

export default StatusFilter

export {
  StatusSelector
}
