import React from 'react'
import Table, { defaultFilterMethod, Filters } from 'components/Table'
import { ActionButton } from 'components/Buttons'
import { Breadcrumbs } from '../components'
import { baseUrl, buildColumns } from './helpers'

const AdminListView = ({ admins, operator, loading, roles }) => {
  const [filterable, setFilterable] = React.useState(false)
  const [columns, setColumns] = React.useState(buildColumns(setFilterable, roles, operator))

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <Breadcrumbs operatorId={operator} />
        Administrators
        <span className='header-action-wrapper'>
          <ActionButton
            txt='New administrator'
            link={`${baseUrl(operator)}/new`}
            icon='pluss white'
          />
        </span>
      </h1>
      <Filters
        columns={columns}
        setColumns={setColumns}
        onChange={() => ({})}
        onSubmit={() => ({})}
        toggleFiltered={setFilterable}
      />
      <div className='content-box admins'>
        <Table
          defaultFilterMethod={defaultFilterMethod}
          columns={columns}
          data={admins}
          config={{ class_name: 'admins' }}
          loading={loading}
          defaultSorted={[{ id: 'status' }]}
          filterable={filterable}
        />
      </div>
    </div>
  )
}

export default AdminListView
