// Monthly payment discount rewards
const referrerPaymentDiscountReward = (promotion) => {
  const discount = promotion.referrer_discount_percentage
  const months = promotion.referrer_discount_months

  return {
    discount: discount,
    months: months
  }
}

const referrerDiscountText = (promotion) => {
  const paymentReward = referrerPaymentDiscountReward(promotion)

  if (paymentReward.discount && paymentReward.months) {
    return `${paymentReward.discount}% for ${paymentReward.months} months`
  } else {
    return ''
  }
}

// PT Hours
const referrerPTHours = (promotion) =>
  promotion.referrer_pt_hours

const referrerPTHoursText = (promotion) => {
  const ptHours = referrerPTHours(promotion)
  let txt = ''
  if (ptHours) {
    txt = `${ptHours} PT hour`
    if (ptHours > 1) txt += 's'
  }

  return txt
}

// Returns a human readable text string for rewards
// e.g. "50% for 2 months and 2 PT hours"
const promotionReferrerRewardsToText = promotion =>
  [referrerDiscountText(promotion), referrerPTHoursText(promotion)]
    .filter(rewardText => !!rewardText) // filter out empty strings
    .join(' and ')

export {
  promotionReferrerRewardsToText,
  referrerDiscountText
}
