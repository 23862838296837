import React, { Component } from 'react'
import MembersAPI from 'services/api/members'
import MemberWrapper from './components/MemberWrapper'
import NoAccess from '../../../../NoAccess'
import { hasAccess } from 'components/Helpers/index'
import AppContext from '../../../../../AppContext'

const permission = 'memberships.member.get'

const memberState = (location) =>
  // FIXME: Hack to ensure that we don't use state if it isn't a member state
  // NOTE: Addes an additional level to allow development on invoices list -> invoice view
  location && location.state && location.state.obj && location.state.obj.customer

class Member extends Component {
  static contextType = AppContext

  constructor (props) {
    super(props)

    this.state = { member: null }

    this.memberFromServer = this.memberFromServer.bind(this)
  }

  componentDidMount () {
    if (memberState(this.props.location)) {
      // No need to fetch from api if invoice given as state
      this.setState({ member: this.props.location.state.obj })
    } else {
      this.memberFromServer()
    }
  }

  memberFromServer () {
    if (!hasAccess(this.props.user, permission)) {
      // There is really no need to fetch data
      // unless the user has access :D
      return
    }
    const { operatorID, memberID } = this.props.match.params

    MembersAPI.fetchMember(operatorID, memberID, (member) => {
      this.setState({ member: member })
    })
  }

  render () {
    const { user } = this.props
    const { operator } = this.context
    if (!hasAccess(user, permission)) {
      return <NoAccess permission={permission} />
    }

    const { member } = this.state
    if (!member) { return (<div>Loading</div>) }

    return (
      <div className='content-wrapper'>
        <MemberWrapper
          member={member}
          match={this.props.match}
          user={this.props.user}
          location={this.props.location}
          memberFromServer={this.memberFromServer}
          timezone={operator.timezone}
        />
      </div>
    )
  }
}

export default Member
