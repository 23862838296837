import React from 'react'
import Modal from 'components/Modal'
import { ActionButton } from 'components/Buttons'
import { put } from 'services/api/api'

const curr = new Date()
curr.setDate(curr.getDate() + 1)
const date = curr.toISOString().substr(0, 10)

const Success = ({ onClose, response }) => {
  return (
    <Modal>
      <h1 className='modal-header'>Support PIN sent</h1>
      <div className='model-content' style={{ margin: '3em 5em', textAlign: 'center' }}>
        <p style={{ marginBottom: '3em' }}>
          Code: {response.code}
        </p>
        <ActionButton txt='Close' onClick={onClose} />
      </div>
    </Modal>
  )
}

const Error = ({ onClose, response }) => {
  return (
    <Modal>
      <h1 className='modal-header'>Support PIN not sent</h1>
      <div className='model-content' style={{ margin: '3em 5em', textAlign: 'center' }}>
        <p style={{ marginBottom: '3em' }}>
          Error: {response.message || 'Something went wrong...'}
        </p>
        <ActionButton txt='Close' onClick={onClose} />
      </div>
    </Modal>
  )
}

const SupportPinModal = ({ onClose, membership }) => {
  let message = 'Hallo $firstName! Dein EVO-PIN-Code lautet: $pinCode (mit # abschließen). Der PIN-Code ist gültig bis zum $pinValidTo. Mit freundlichen Grüßen, EVO Fitness'
  if (membership.locale === 'nb-NO') {
    message = 'Hei $firstName! Din EVO PIN-kode er: $pinCode, husk å avslutte med #. PIN-koden er gyldig til $pinValidTo. God trening! Mvh EVO'
  }

  const [body, setBody] = React.useState({
    valid_to: date,
    message: message,
    first_name: membership.customer.first_name
  })

  const [response, setResponse] = React.useState(null)

  // console.log(membership)

  const sendPin = () => {
    return put(`memberships/${membership.id}/pin`, body)
      .then(setResponse)
  }

  const onChange = (event) => {
    const { value, name } = event.target
    body[name] = value
    setBody(body)
  }

  if (response && response.code) return <Success response={response} onClose={onClose} />
  if (response && !response.code) return <Error response={response} onClose={onClose} />

  return (
    <Modal>
      <h1 className='modal-header'>Support PIN</h1>
      <div className='model-content' style={{ margin: '3em 5em' }}>
        <form onSubmit={sendPin} style={{ lineHeight: '20px' }}>
          <div style={{ marginBottom: '1em' }}>
            <label style={{ marginBottom: 0 }}>Valid to:</label>
            <input
              name='valid_to'
              type='date'
              defaultValue={body.valid_to}
              onChange={onChange}
            />
          </div>
          <div style={{ marginBottom: '1em' }}>
            <label style={{ marginBottom: 0 }}>Message:</label>
            <textarea
              name='message'
              style={{ width: '100%', height: '10em', lineHeight: '20px' }}
              defaultValue={message}
              onChange={onChange}
            />
          </div>
        </form>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ActionButton
            txt='Cancel'
            onClick={onClose}
          />
          <ActionButton
            txt='Send message'
            onClick={sendPin}
          />
        </div>
      </div>
    </Modal>
  )
}

export default SupportPinModal
