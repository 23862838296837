import React from 'react'
import CurrentVsPreviousPeriod from './CurrentVsPreviousPeriod'

/* eslint-disable camelcase */
const CurrentVsPreviousPeriodList = ({ data, isGood }) => {
  const {
    today,
    current_month,
    current_year,
    percent_change_since_yesterday,
    percent_change_since_same_month_last_year_to_date,
    percent_change_since_last_year_to_date
  } = data

  return (
    <div className='current-vs-previous-period-list'>
      <CurrentVsPreviousPeriod
        header='Today'
        currentValue={today}
        percentageChange={percent_change_since_yesterday}
        footer='versus yesterday'
        isGood={isGood}
      />
      <CurrentVsPreviousPeriod
        header='This month'
        currentValue={current_month}
        percentageChange={percent_change_since_same_month_last_year_to_date}
        footer='versus same month last year'
        isGood={isGood}
      />
      <CurrentVsPreviousPeriod
        header='This year'
        currentValue={current_year}
        percentageChange={percent_change_since_last_year_to_date}
        footer='versus last year'
        isGood={isGood}
      />
    </div>
  )
}

export default CurrentVsPreviousPeriodList
