import React, { useState, useEffect } from 'react'
import PT_API from 'services/api/pts'
import locationAPI from 'services/api/locations'
import { toast } from 'react-toastify'
import { viewPTUrl, Breadcrumbs, LocationsWithRole } from './components'
import { Form, Submit, FormGroup, FormButtons } from 'components/Forms'

const EditLocations = ({ match, history }) => {
  const { id, operatorID: operatorId } = match.params
  const [pt, setPT] = useState(null)
  const [validationErrors, setValidationErrors] = useState({})

  const [availableRoles, setAvailableRoles] = useState([])
  useEffect(() => {
    PT_API.fetchRoles(operatorId, (successfulResult) =>
      setAvailableRoles(successfulResult.data)
    )
  }, [operatorId])

  const [availableLocations, setAvailbleLocations] = useState([])
  useEffect(() => {
    locationAPI.fetchLocations(operatorId, {}, (successfulResult) =>
      setAvailbleLocations(successfulResult.data)
    )
  }, [operatorId])

  useEffect(() => {
    PT_API.fetchPT(operatorId, id, setPT)
  }, [id, operatorId])

  const setLocations = (locations) => setPT({ ...pt, roles: locations })

  if (!pt) return null

  const onSubmit = () => {
    const roles = pt.roles ? pt.roles.filter(loc => loc.location_id !== '') : pt.roles
    PT_API.update(
      { roles: roles, id },
      id,
      (successfulResult) => {
        toast.success('Changes saved')
        history.push(viewPTUrl(id, operatorId))
      },
      (errorResult) => {
        console.error('failed to update', errorResult)
        toast.error(errorResult)
        setValidationErrors(errorResult)
      }
    )
  }

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <Breadcrumbs operatorId={operatorId} pt={pt} />
        Edit PT locations
      </h1>
      <div className='form'>
        <Form
          onSubmit={onSubmit}
          defaultValues={pt}
          validationErrors={validationErrors}
        >
          <FormGroup header='Locations'>
            <LocationsWithRole
              locationsWithRole={pt.roles}
              setLocationsWithRole={setLocations}
              availableLocations={availableLocations}
              availableRoles={availableRoles}
            />
          </FormGroup>
          <FormButtons>
            <Submit label='Update' />
          </FormButtons>
        </Form>
      </div>
    </div>
  )
}

export default EditLocations
