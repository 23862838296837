import React from 'react'
import Badge from 'components/Badges'
import { Button } from 'components/Buttons'
import Table from 'components/Table'
import AppContext from 'AppContext'

const PromotionModalList = ({ promotions, onFetchData, moveToPromotion, currentPromotion, loading, pages }) =>
  <Table
    style={{ marginBottom: '20px', marginTop: '20px' }}
    data={promotions}
    pages={pages}
    serverSidePagination
    defaultPageSize={9}
    onFetchData={onFetchData}
    loading={loading}
    columns={[
      {
        width: 120,
        Header: 'Type',
        accessor: 'promotion_type',
        hide_for: {
          phone: true
        },
        Cell: props => {
          return <Badge className='black' txt={props.value.toUpperCase()} />
        }
      },
      {
        Header: 'Name',
        width: 250,
        Cell: (props) => {
          const promotion = props.original
          const baseUrl = `/operators/${promotion.operator_id}`
          return (
            <a href={`${baseUrl}/promotions-v2/${promotion.promotion_type}/${promotion.id}`}>
              {promotion.name}
            </a>
          )
        }
      },
      {
        Header: 'Binding',
        accessor: 'binding_period_in_months',
        width: 75
      },
      {
        Header: 'Payment schedule',
        minWidth: 150,
        Cell: (props) => {
          const promotion = props.original
          const { operator } = React.useContext(AppContext)

          // Check if all null
          if (promotion.monthly_payment_schedule.every(entry => !entry.fee_as_percentage && !entry.fee_as_amount)) {
            return 'Free'
          }

          function partitionBy (arr, keyFn) {
            if (arr.length === 0) return []

            const partitions = []
            let currentPartition = [arr[0]]

            for (let i = 1; i < arr.length; i++) {
              if (keyFn(arr[i]) === keyFn(arr[i - 1])) {
                currentPartition.push(arr[i])
              } else {
                partitions.push(currentPartition)
                currentPartition = [arr[i]]
              }
            }

            partitions.push(currentPartition) // Push the last partition
            return partitions
          }

          const groups = partitionBy(promotion.monthly_payment_schedule
            .map(month => ({
              ...month, fee: month.fee_as_percentage ? `${month.fee_as_percentage}%` : `${month.fee_as_amount / 100} ${operator.currency}`
            })), item => item.fee)

          return groups.map((similarMonths, index, arr) => {
            let key = null
            if (index === arr.length - 1) {
              key = `Month ${similarMonths[0].begin_month_index} --> `
            } else {
              const first = similarMonths[0]
              const last = similarMonths[similarMonths.length - 1]
              key = first === last
                ? `Month ${first.begin_month_index}: `
                : `Month ${first.begin_month_index}-${last.begin_month_index}: `
            }
            return `${key} ${similarMonths[0].fee}`
          }).join(', ')
        }
      },
      {
        maxWidth: 200,
        Header: '',
        Cell: (props) => {
          if (currentPromotion && currentPromotion.id === props.original.id) {
            return (
              <Button
                disabled
                txt='Current promotion'
              />
            )
          } else {
            return (
              <Button
                txt='Move to'
                onClick={() => moveToPromotion(props.original)}
              />
            )
          }
        }
      }
    ]}
  />

export default PromotionModalList
