import React from 'react'

const PTsIcon = props => (
  <svg width='1em' height='1em' viewBox='0 0 20 20' {...props}>
    <g>
      <path d='M5.51,11h-5c-0.276,0-0.5,0.224-0.5,0.5v12c0,0.276,0.224,0.5,0.5,0.5h5c0.276,0,0.5-0.224,0.5-0.5v-12
        C6.01,11.224,5.787,11,5.51,11z'
      />
      <path d='M14.49,0h-5c-0.276,0-0.5,0.224-0.5,0.5v23c0,0.276,0.224,0.5,0.5,0.5h5c0.276,0,0.5-0.224,0.5-0.5v-23
        C14.99,0.224,14.766,0,14.49,0z'
      />
      <path d='M23.49,5h-5c-0.276,0-0.5,0.224-0.5,0.5v18c0,0.276,0.224,0.5,0.5,0.5h5c0.276,0,0.5-0.224,0.5-0.5v-18
        C23.99,5.224,23.766,5,23.49,5z'
      />
    </g>
  </svg>
)

export default PTsIcon
