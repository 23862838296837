import React from 'react'
import PropTypes from 'prop-types'
import BreadCrumb from './BreadCrumb'

/**
 * @component
 * @example:
 *  <BreadCrumbs
 *    list={[
 *      { path: '/', text: 'Home' },
 *      { path: '/dashboard', text: 'Dashboard' },
 *    ]}
 *  />
 */
const BreadCrumbs = ({ list }) => {
  return (
    <span className='breadcrumbs'>
      {
        list
          .map(l => <BreadCrumb key={l.text} {...l} />)
          .reduce((prev, curr) => [prev, ' > ', curr])
      }
      {list.a}
    </span>
  )
}

BreadCrumbs.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      state: PropTypes.object
    })
  ).isRequired
}

export default BreadCrumbs
