import React, { useState } from 'react'
import useReport from './components/helpers/useReport'
import CurrentVsPreviousPeriodList from './components/CurrentVsPreviousPeriodList'

const ActivationStatus = ({ location_id = '' }) => {
  const [reportArgs] = useState({ location_id })

  const { data } = useReport('dashboard-activations', reportArgs)

  if (!data) return null

  const isGood = change => change >= 0

  return (
    <div className='report-box'>
      <h2>activations</h2>
      <CurrentVsPreviousPeriodList data={data[0]} isGood={isGood} />
    </div>
  )
}

export default ActivationStatus
