import React from 'react'

const Modal = ({ children, className }) =>
  <div>
    <div className='modal-overlay' />
    <div className={'modal ' + className}>
      {children}
    </div>
  </div>

export default Modal
