import React from 'react'

const TopBarLogo = props => (
  <svg width='1em' height='1em' viewBox='0 0 51 51' {...props}>
    <title>Asset 4</title>
    <defs>
      <linearGradient x1='4.781%' y1='4.77%' x2='107.563%' y2='107.552%' id='top-bar-logo-a'>
        <stop stopColor='#EF4266' offset='0%' />
        <stop stopColor='#24136D' offset='100%' />
      </linearGradient>
      <linearGradient
        x1='-53.486%'
        y1='-57.881%'
        x2='219.18%'
        y2='237.373%'
        id='top-bar-logo-b'
      >
        <stop stopColor='#EF4266' offset='0%' />
        <stop stopColor='#24136D' offset='100%' />
      </linearGradient>
      <linearGradient
        x1='-166.224%'
        y1='-583.057%'
        x2='281.783%'
        y2='708.766%'
        id='top-bar-logo-c'
      >
        <stop stopColor='#EF4266' offset='0%' />
        <stop stopColor='#24136D' offset='100%' />
      </linearGradient>
      <linearGradient
        x1='-132.133%'
        y1='-594.442%'
        x2='216.233%'
        y2='614.099%'
        id='top-bar-logo-d'
      >
        <stop stopColor='#EF4266' offset='0%' />
        <stop stopColor='#24136D' offset='100%' />
      </linearGradient>
      <linearGradient
        x1='-109.959%'
        y1='-609.942%'
        x2='173.65%'
        y2='534.083%'
        id='top-bar-logo-e'
      >
        <stop stopColor='#EF4266' offset='0%' />
        <stop stopColor='#24136D' offset='100%' />
      </linearGradient>
    </defs>
    <g fillRule='nonzero' fill='none'>
      <path
        d='M25.5 0a25.5 25.5 0 1 0 0 51 25.5 25.5 0 0 0 0-51zm0 47.333c-12.06 0-21.838-9.777-21.838-21.838 0-12.061 9.777-21.839 21.838-21.839 12.061 0 21.839 9.778 21.839 21.839-.011 12.056-9.782 21.827-21.839 21.838z'
        fill='url(#top-bar-logo-b)'
      />
      <path
        d='M25.493 9c-4.237 0-7.96 2.88-9.126 7.058-1.165 4.178.51 8.643 4.104 10.942l1.294-2.125c-2.678-1.704-3.93-5.023-3.066-8.132.863-3.109 3.632-5.253 6.784-5.253 3.151 0 5.92 2.144 6.784 5.253.863 3.109-.388 6.428-3.067 8.132L30.53 27c3.597-2.3 5.271-6.77 4.102-10.949-1.17-4.18-4.9-7.057-9.138-7.051z'
        fill='url(#top-bar-logo-b)'
      />
      <path
        d='M31 31.314L30.038 29c-3.012 2.074-7.064 2.074-10.076 0L19 31.319a11.548 11.548 0 0 0 12 0v-.005z'
        fill='url(#top-bar-logo-c)'
      />
      <path
        d='M18 35.146c4.643 2.472 10.357 2.472 15 0L32.075 33c-4.032 2.291-9.113 2.291-13.145 0L18 35.146z'
        fill='url(#top-bar-logo-d)'
      />
      <path
        d='M16.899 38L16 40.023c5.64 2.636 12.36 2.636 18 0L33.101 38c-5.055 2.448-11.147 2.448-16.202 0z'
        fill='url(#top-bar-logo-e)'
      />
    </g>
  </svg>
)

export default TopBarLogo
