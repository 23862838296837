const API = require('./api')
const URL = 'admins'

const fetchAdmin = (operatorID, adminID, success) => {
  const url = URL + '/' + adminID
  API.fetchWithError(url, success)
}

const fetchAdmins = (operatorID, success, error) => {
  API.fetchWithError(URL, success, error)
}

const fetchRoles = (operatorID, success, error) => {
  API.fetchWithError('roles', success, error)
}

const create = (args, operatorId, success, error) => {
  const url = process.env.REACT_APP_SERVER + '/' + URL
  API.post(url, success, args, error)
}

const update = (args, success, error) => {
  const url = process.env.REACT_APP_SERVER + '/' + URL + '/' + args.id
  API.patch(url, success, args, error)
}

module.exports = {
  create,
  update,
  fetchAdmins,
  fetchAdmin,
  fetchRoles
}
