const compare = (last, compare) => {
  const normalize = object => {
    return JSON.stringify({
      fee_as_amount: object.fee_as_amount,
      fee_as_percentage: object.fee_as_percentage
    })
  }
  if (!last) {
    return false
  }

  return normalize(last) === normalize(compare)
}

const groupPaymentScheduleByPrice = content => {
  let last = null
  return content.reduce((acc, cur) => {
    if (compare(last, cur)) {
      const index = acc.length - 1
      acc[index] = [...acc[index], cur]
    } else {
      acc.push([cur])
    }

    last = cur
    return acc
  }, [])
}

export default groupPaymentScheduleByPrice
