import React from 'react'
import { dateToLocale, timeToLocale } from 'components/Helpers'

const salesPeriod = (promotion, time = true, timezone) => {
  let from
  let to

  if (time) {
    from = timeToLocale(promotion.sales_period_begins_at, timezone)
    to = timeToLocale(promotion.sales_period_ends_at, timezone)
  } else {
    from = dateToLocale(promotion.sales_period_begins_at)
    to = dateToLocale(promotion.sales_period_ends_at)
  }

  return { from: from, to: to }
}

const SalesPeriod = ({ promotion, style, time = true, timezone }) => {
  const { from, to } = salesPeriod(promotion, time, timezone)

  return (
    <span style={style}>
      {from} <br /> {to}
    </span>
  )
}

export default SalesPeriod

export {
  salesPeriod
}
