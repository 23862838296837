const API = require('./api')
const baseURL = 'locations'

const create = (args, success, error) => {
  const url = process.env.REACT_APP_SERVER + '/' + baseURL
  API.post(url, success, args, error)
}

const update = (args, success, error) => {
  const url = process.env.REACT_APP_SERVER + '/' + baseURL + '/' + args.id
  API.patch(url, success, args, error)
}

const fetchLocations = (operatorID, params, success) => {
  API.fetch(baseURL, success, params)
}

const fetchLocation = (operatorID, locationID, success) => {
  const url = 'locations/' + locationID
  API.fetch(url, success)
}

const fetchRegions = success => {
  API.fetch('regions', success)
}

const fetchAdministrativeAreas = success => {
  API.fetch('administrative-areas', success)
}

module.exports = {
  fetchLocation,
  fetchLocations,
  fetchRegions,
  fetchAdministrativeAreas,
  create,
  update
}
