import React from 'react'

const SwitchWithLabelRight = ({ checked, onClick, label = 'Sheltered. Automatic cancellation protection', inputName = '' }) =>
  <span>
    <span className='switch-label'>
      {label}
    </span>
    <label style={{ opacity: 1 }}>
      <span className='switch'>
        <input
          type='checkbox'
          name={inputName}
          checked={checked}
          onClick={onClick}
          readOnly
        />
        <span className='slider round' />
      </span>
    </label>
  </span>

export default SwitchWithLabelRight
