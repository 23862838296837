import React from 'react'
import PromotionNavigation from './components/PromotionNavigation'
import { ActionButton } from 'components/Buttons'

const PromotionLists = (props) =>
  <div className='index sales-promotions'>
    <h1>
      Promotions
      <span className='header-action-wrapper'>
        <ActionButton
          txt='Add new promotion'
          link={`/operators/${props.match.params.operatorID}/promotions/sales/new`}
          icon='pluss white'
        />
      </span>
    </h1>
    <PromotionNavigation match={props.match} user={props.user} />
  </div>

export default PromotionLists
