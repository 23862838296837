import React, { useState, useEffect } from 'react'
import AppContext from 'AppContext'
import FreePassAPI from 'services/api/free_passes'
import { Redirect } from 'react-router-dom'
import {
  viewFreePassListUrl,
  Breadcrumbs
} from './components'
import {
  Form,
  FormLine,
  TextInput,
  TextAreaInput,
  DateInput,
  EmailInput,
  Switch,
  Mobile,
  Submit,
  RadioInput
} from 'components/Forms'

const NewFreePass = () => {
  const { operator: { id: operatorId } } = React.useContext(AppContext)
  const [newFreePassId, setNewFreePassId] = useState(null)
  const [validationErrors, setValidationErrors] = useState({})
  const [locales, setLocales] = useState([])

  useEffect(() => {
    FreePassAPI.locales(
      operatorId,
      success => {
        if (success.content.status === 200) {
          const [default_locale, all_locales] = success.content.data.locales
          const data = [...new Set([default_locale, ...all_locales])]
            .map((x, i) => (
              {
                value: x,
                label: localeLabels[x] || x,
                checked: i === 0
              }))
          setLocales(data)
        }
      },
      error => { console.error('no locales found', error) }
    )
  }, [operatorId])

  const onSubmit = data => {
    FreePassAPI.create(
      { ...data, source: 'admin-ui' },
      operatorId,
      successfulResult => setNewFreePassId(successfulResult.id),
      errorResult => setValidationErrors(errorResult)
    )
  }

  // TODO: fix CORS issues - fallback on hardcoded values for AT
  // const res = useFetch("https://admin-ui.staging.credlock.net/free-passes/locales?operator_id=051b413f-0245-4e84-9228-f3c7c5f8d299", { mode: 'no-cors', credentials: 'include' })
  // console.log('res', res)
  // if (!res.response) return null

  if (newFreePassId) return <Redirect to={viewFreePassListUrl(operatorId)} />

  return (
    <div className='index'>
      <h1 className='with-breadcrumbs'>
        <Breadcrumbs operatorId={operatorId} />
        New free pass
      </h1>
      <div className='content-box form'>
        <Form onSubmit={onSubmit} defaultValues={{}} validationErrors={validationErrors}>
          <FormLine>
            <DateInput name='begin_date' />
            <DateInput name='end_date' />
          </FormLine>
          <Mobile focus={false} operator={operatorId} />
          <EmailInput name='email' />
          <FormLine>
            <TextInput name='first_name' />
            <TextInput name='last_name' />
          </FormLine>
          <TextAreaInput name='description' />
          <FormLine>
            <TextInput name='company' />
            <TextInput name='title' label='Role' />
          </FormLine>
          <FormLine>
            {locales.map(l => (
              <RadioInput name='locale' label={l.label} value={l.value} checked={l.checked} />
            ))}
          </FormLine>
          <Switch
            label='Send SMS?'
            name='send_sms'
            checked
          />
          <Switch
            label='Grant access to non-public areas such as technical rooms, PT offices etc.'
            name='privileged_access'
            checked
          />
          <Submit label='Add free pass' />
        </Form>
      </div>
    </div>
  )
}

// eslint-disable-next-line
const useFetch = (url, options) => {
  const [response, setResponse] = React.useState(null)
  const [error, setError] = React.useState(null)
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(url, options)
        const json = await res.json()
        setResponse(json)
      } catch (error) {
        setError(error)
      }
    }
    fetchData()
  }, [url, options])
  return { response, error }
}

const localeLabels = {
  'nb-NO': 'Norwegian',
  'de-AT': 'Austrian',
  'en-AT': 'English',
  'de-DE': 'German',
  'en-DE': 'English',
  'de-CH': 'German',
  'en-CH': 'English',
  'fr-CH': 'French',
  'fi-FI': 'Finnish'
}

export default NewFreePass
