import React from 'react'
import UserMessage from './components/UserMessage'
import PaymentType from './components/PaymentType'
import Table from 'components/Table/index'
import { timeToLocale } from 'components/Helpers/dateToLocale'
import { ActionButton } from 'components/Buttons/index'
import { StatusBadge } from 'components/Badges'

const url = (invoice) =>
  `/operators/${invoice.operator_id}/members/${invoice.membership_id}/invoices/${invoice.id}`

const getDeclinedActionCode = (payment) => {
  if (!payment.provider_details) return

  return payment.provider_details.action_code || payment.provider_details.reason_code
}

const getDeclinedDetails = (payment) => {
  if (!payment) return
  if (!payment.payment_method && !payment.provider_details) return

  if (payment.provider_details) {
    if (payment.provider_details.reason_description) {
      if (payment.provider_details.reason_description.cause) {
        return `${payment.provider_details.reason_code}: ${payment.provider_details.reason_description.cause}`
      } else {
        return `${payment.provider_details.reason_code}: ${payment.provider_details.reason_description}`
      }
    }
    if (payment.provider_details.error_code) return `${payment.provider_details.error_code}: ${payment.provider_details.error_message}`
    if (payment.provider_details.details) return payment.provider_details.details
  }
  if (payment.payment_method && payment.payment_method.details) return payment.payment_method.details

  return null
}

const InvoicePaymentsView = ({ invoice, loading, timezone }) => {
  if (loading) return null
  const style = {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '10px'
  }

  return (
    <div className='index'>
      <div className='with-response-margin' style={style}>
        <UserMessage invoice={invoice} timezone={timezone} />
        <ActionButton
          txt='View Invoice'
          icon='report white'
          link={url(invoice)}
        />
      </div>
      <div className='content-box'>
        <Table
          className='payment-list'
          data={invoice.payments}
          loading={loading}
          columns={[
            {
              Header: 'Status',
              accessor: 'status',
              Cell: ({ original }) => {
                let { status } = original
                const actionCode = getDeclinedActionCode(original)

                if (actionCode && actionCode !== '0000') { // '0000' is Verifone success code
                  status = `${status} ${actionCode} `
                }

                return <StatusBadge status={status} cssStatus={original.status} />
              }
            },
            {
              Header: 'Charged at',
              Cell: props =>
                timeToLocale(props.original.created_at, timezone)
            },
            {
              Header: 'Payment type',
              Cell: props =>
                <PaymentType paymentMethod={props.original.payment_method} />
            },
            {
              Header: 'Details',
              Cell: props => getDeclinedDetails(props.original)
            }
          ]}
          defaultSorted={[
            {
              id: 'created_at',
              desc: true
            }
          ]}
        />
      </div>
    </div>
  )
}

export default InvoicePaymentsView
