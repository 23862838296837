import React from 'react'
import { Link } from 'react-router-dom'
import { ActionButton } from 'components/Buttons'
import { DetailBox } from 'components/Boxes'

const ListItem = ({ children, type, link = '/no-access' }) => {
  const style = { display: 'inline-block', fontWeight: 'bold', width: '40px' }

  return (
    <div style={{ paddingBottom: '2px' }}>
      <span style={style}>
        {type ? type.toUpperCase() : ''}
      </span>
      <Link to={link}>
        {children}
      </Link>
    </div>
  )
}

const includeInReport = ({ monthly_reports: reports }) => {
  const textArray = [<ListItem key='membership'>Memberships report</ListItem>]

  if (reports.include_access_log_with_names) {
    textArray.push(
      <ListItem key='log with names'>
        Visits report with names
      </ListItem>
    )
  }

  if (reports.include_access_log_without_names) {
    textArray.push(
      <ListItem key='log without names'>
        Visits report without names
      </ListItem>
    )
  }

  return textArray
}

const emails = ({ monthly_reports: reports }) => {
  return ['email_to', 'email_cc', 'email_bc'].map(e => {
    let type = e.split('_')[1]
    if (type === 'bc') type = 'bcc'

    return (
      <ListItem key={type} type={type}>
        {reports[e]}
      </ListItem>
    )
  })
}

const CorporateReports = ({ promotion }) => {
  if (promotion.promotion_type !== 'corporate') return null
  const items = [
    { label: 'Include', value: includeInReport(promotion) },
    { label: 'E-mail to', value: emails(promotion) }
  ]

  return (
    <DetailBox header='Report settings' items={items}>
      <ActionButton
        txt='Edit report settings'
        icon='edit white'
        link='/under-development'
      />
    </DetailBox>
  )
}

export default CorporateReports
