import React from 'react'

import Modal from 'components/Modal'
import { ActionButton } from 'components/Buttons'

// TODO: Move CSS out of component
const MarkPaidModalView = ({ invoice, loading, onSubmit, onClose, onChange }) => {
  if (loading) {
    return null
  }

  return (
    <Modal>
      <h1 className='modal-header'>Mark Invoice Paid</h1>
      <div className='model-content' style={{ margin: '3em 5em' }}>
        <form>
          <div style={{ marginBottom: '3em' }}>
            <label style={{ marginBottom: 0 }}>Where Paid</label>
            <textarea
              name='reasonPaid'
              onChange={onChange}
              style={{ width: '100%', height: '10em' }}
              placeholder='Explanation of payment'
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ActionButton
              txt='Cancel'
              onClick={onClose}
            />
            <ActionButton
              txt='Mark Paid'
              onClick={onSubmit}
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default MarkPaidModalView
