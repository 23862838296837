const DEMO = require('Constants').DEMO
const baseURL = process.env.REACT_APP_SERVER

const isAObj = (value) => value !== null && typeof value === 'object'

const encodeURIKeyValue = (key, value) =>
  encodeURIComponent(key) + '=' + encodeURIComponent(value)

const serialize = (obj, prefix) => {
  const str = []

  Object.keys(obj).forEach((k) => {
    const key = prefix ? prefix + '[' + k + ']' : k
    const value = obj[k]

    const partialString = isAObj(value)
      ? serialize(value, key)
      : encodeURIKeyValue(key, value)

    str.push(partialString)
  })

  return str.join('&')
}

const paramsToString = (params) => {
  if (!params) return ''
  return '?' + serialize(params)
}

const buildUrl = (baseURL, url, params) => {
  if (!url && !params) {
    return baseURL
  }
  return baseURL + '/' + url + paramsToString(params)
}

const headers = () => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: window.localStorage.getItem('auth-token') || ''
  }
}

const FetchWithError = (url, success, error, params = null) => {
  FetchFromApi(url, params, success, error)
}

const Fetch = (url, success, params = null) => {
  FetchFromApi(url, params, success)
}

const Post = (url, success, body, error = () => {}) => {
  console.log('post:', url)
  PostToApi(url, success, error, body)
}

const Patch = (url, success, body, error = () => {}) => {
  PatchToApi(url, success, error, body)
}

// NOTE: this differs from the rest. More direct use of fetch
const put = (url, body) =>
  fetch(buildUrl(baseURL, url), {
    headers: headers(),
    method: 'PUT',
    body: JSON.stringify(body)
  }).then((response) => response.json())

const FetchFromApi = (url, params, success, error) => {
  let resStatus
  const fullURL = buildUrl(baseURL, url, params)

  fetch(fullURL, {
    headers: headers(),
    method: 'GET'
  })
    .then(function (response) {
      resStatus = response.status
      return response.json()
    })
    .then(function (json) {
      // console.log('json', json)
      // console.log('resStatus', resStatus)
      if (resStatus === 401 || resStatus === 403) {
        // Remove storage and redirect to login
        window.localStorage.removeItem('auth-token')
        window.location = '/login'
      }
      if (resStatus === 200) {
        success(json)
      } else {
        error(json)
      }
    })
    .catch(function (ex) {
      if (error) {
        error()
      } else {
        console.log('fetch from ', url, 'failed', ex)
      }
    })
}

const PostToApi = (url, success, error, body) => {
  let resStatus
  fetch(url, {
    headers: headers(),
    method: 'POST',
    body: JSON.stringify(body)
  })
    .then((response) => {
      resStatus = response.status

      const contentType = response.headers.get('content-type')
      if (contentType && contentType.includes('application/json')) {
        return response.json()
      } else {
        return response.text() // handle non-json response
      }
    })
    .then((data) => {
      if (resStatus >= 200 && resStatus <= 299) {
        success(data)
      } else {
        console.log('postToApi response:', { resStatus }, { data })
        error(data, resStatus)
      }
    })
    .catch((err) => {
      console.log('POST failed', err)
      if (err instanceof TypeError) {
        error(err.message)
      } else {
        error(err)
      }
    })
}

const PatchToApi = (url, success, error, body) => {
  let resStatus
  fetch(url, {
    headers: headers(),
    method: 'PATCH',
    body: JSON.stringify(body)
  })
    .then(function (response) {
      resStatus = response.status
      // console.log('response', response)
      return response.json()
    })
    .then(function (json) {
      if ([200, 201].indexOf(resStatus) > -1) {
        success(json)
      } else {
        error(json, resStatus)
      }
    })
    .catch((err) => {
      console.error(err)
      error(err.message)
    })
}

if (DEMO) {
  const mockAPI = require('./mock_api')
  module.exports = {
    fetch: mockAPI.fetch,
    fetchWithError: mockAPI.fetchWithError,
    post: mockAPI.post,
    patch: mockAPI.patch,
    buildUrl: buildUrl
  }
} else {
  module.exports = {
    fetch: Fetch,
    fetchWithError: FetchWithError,
    post: Post,
    patch: Patch,
    buildUrl: buildUrl,
    put: put
  }
}
