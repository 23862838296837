import React from 'react'

import humanize from './humanize'
import ErrorMessage from './ErrorMessage'
import { useFormContext } from 'react-hook-form'

const TextAreaInput = ({ label, name, required = false, ...rest }) => {
  const { register, errors } = useFormContext()

  return (
    <label>{label || humanize(name)}
      <textarea
        className={errors[name] ? 'error' : ''}
        name={name}
        ref={
          register({ required: required })
        }
        {...rest}
      />
      <ErrorMessage name={name} errors={errors} />
    </label>
  )
}

export default TextAreaInput
