import React from 'react'
import AppContext from 'AppContext'

const KeyManagement = () => {
  let env = 'staging'
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = 'production'
  }
  const token = window.localStorage.getItem('auth-token')
  const { operator } = React.useContext(AppContext)

  // We may have to wait for AppContext to be available
  if (!operator.id) {
    return (
      <div className='index key-management'>
        <p>waiting for operator...</p>
      </div>
    )
  }

  return (
    <cl-key-loader
      mountPath={`/operators/${operator.id}/key-management`}
      apiUrl={`https://access-control.${env}.credlock.net/api`}
      operatorName={operator.name}
      token={token}
    />
  )
}

const KeyManagementWrapper = ({ permission, history }) => {
  return (
    <div className='key-management-wrapper'>
      <KeyManagement />
    </div>
  )
}

export default KeyManagementWrapper
