import React, { Component } from 'react'
import RefundModalView from './RefundModalView'
import RefundSuccessView from './RefundSuccessView'
import RefundErrorView from './RefundErrorView'
import { refundInvoice } from 'services/api/invoices'

class RefundModal extends Component {
  constructor (props) {
    super(props)

    // TODO: Extract calculation of amountToRefund
    this.state = {
      status: null,
      error: null,
      amountToRefund: (props.invoice.amount / 100).toFixed(2)
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  handleSubmit (ev) {
    ev.preventDefault()

    const args = {
      amount: parseInt((this.state.amountToRefund * 100).toFixed(0)),
      reason: this.state.reasonToRefund
    }

    // console.log('refund args', args)

    refundInvoice(args, this.props.invoice.membership_id, this.props.invoice.id, (res) => {
      // console.log(res)
      if (res.status === 'accepted') {
        this.setState({ status: 'success' })
      } else {
        this.setState({ status: 'error', error: 'Refund declined by provider' })
      }
    }, (err) => {
      console.log(err)
      this.setState({ status: 'error' })
    })
  }

  render () {
    const { status, amountToRefund } = this.state

    if (status === 'success') {
      return (
        <RefundSuccessView
          onClose={this.props.onClose}
          amount={amountToRefund}
          currency={this.props.invoice.currency}
        />
      )
    } else if (status === 'error') {
      return (
        <RefundErrorView
          onClose={this.props.onClose}
          error={this.state.error}
        />
      )
    } else {
      return (
        <RefundModalView
          invoice={this.props.invoice}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          onClose={this.props.onClose}
          {...this.state}
        />
      )
    }
  }
}

export default RefundModal
