import React, { Component } from 'react'
import LocationsAPI from 'services/api/locations'
import { exportToExcel } from '../../../components/Table/helpers'
import LocationListView from './LocationListView'

class Locations extends Component {
  constructor (props) {
    super(props)
    this.state = {
      locations: [],
      selected_operator: props.selected_operator
    }
  }

  // TODO: add location and props and remove this?
  componentDidMount () {
    this._fetchLocations(this.props.selected_operator)
  }
  
  render () {
    return (
      <LocationListView
        data={this.state.locations}
        search={this.search}
        onFilterChange={this.onFilterChange}
        exportToExcel={this.exportToExcel}
      />
    )
  }

  search = (value) => {
    this._fetchLocations(
      this.props.selected_operator,
      { filter: { search: value } }
    )
  }

  onFilterChange = (filtered) => {
    const params = Object.assign(
      {},
      this.state.params,
      { filter: filtered }
    )

    if (!filtered) delete params.filter

    this._fetchLocations(this.props.selected_operator,  params)
  }

  // TODO: We actually don't have to refetch the locations
  //  since we have them all in this.state.locations
  exportToExcel = (columns) => {
    exportToExcel(
      columns,
      { ...this.state.params, page_size: this.state.locations.length },
      this.props.selected_operator,
      LocationsAPI.fetchLocations,
      {},
      'locations_export.xls'
    )
  }

  _fetchLocations = (operatorID, params = {}) => {
    LocationsAPI.fetchLocations(operatorID, params,  (res) => {
      this.setState({locations: res.data, params: params })
    })
  }
}

export default Locations
