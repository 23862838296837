import React, { useEffect, useState } from 'react'
import humanize from './humanize'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from './ErrorMessage'

const MoneyInput = ({ label, name, currency, required = false, ...rest }) => {
  const { register, errors, setValue, getValues } = useFormContext()

  useEffect(() => {
    register({ name: name, required: required })
  }, [register, name, required])

  const [amount, setAmount] = useState(amountToDecimal(getValues()[name] || 0))

  const onChange = (e) => {
    // console.log('onChange', e);
    const v = e.target.value
    setValue(name, amountToInteger(v))
    setAmount(v)
  }

  return (
    <label>{label || humanize(name)}
      <input
        type='hidden'
        name={name}
        ref={register({ required: required })}
      />
      <input
        type='number'
        name={'_' + name}
        className={errors[name] ? 'error' : ''}
        step='1.00'
        pattern='[0-9]+([\.,][0-9]+)?'
        value={amount}
        onChange={onChange}
      /> {currency}
      <ErrorMessage name={name} errors={errors} />
    </label>
  )
}

function amountToInteger (value) {
  if (value === '') { return null }

  if (isNaN(value)) { return null }

  // Converts the value from a string to a float,
  // but also replaces ',' with '.'
  // so that parseFloat includes the decimals even when ',' used
  const decimalNumber = parseFloat(value.replace(/,/g, '.'))

  // This is the line that ensures that we fix the
  // rounding issue for parseFloat, but since this returns a string
  // we have to use parseInt when returning
  const integerString = (decimalNumber * 100).toFixed(0)

  return parseInt(integerString)
}

function amountToDecimal (v) {
  if (v === null) return null
  return isNaN(v) ? null : (v / 100).toFixed(2)
}

export default MoneyInput
