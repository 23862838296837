import React from 'react'
import AppContext from 'AppContext'

const Monitoring = () => {
  let env = 'staging'
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = 'production'
  }
  const token = window.localStorage.getItem('auth-token')
  const { operator } = React.useContext(AppContext)

  // We may have to wait for AppContext to be available
  if (!operator.id) {
    return (
      <div className='index monitoring'>
        <p>waiting for operator...</p>
      </div>
    )
  }

  return (
    <div>
      <cl-alerts
        mountPath={`/operators/${operator.id}/monitor`}
        apiHost={`https://donedone.${env}.credlock.net`}
        country={operator.name.toUpperCase()}
        token={token}
        title='Active Alerts'
        active='true'
      />
      <cl-access-monitor
        mountPath={`/operators/${operator.id}/monitor`}
        apiUrl={`https://access-control.${env}.credlock.net/api`}
        operatorid={operator.id}
        token={token}
      />
      <cl-alerts
        mountPath={`/operators/${operator.id}/monitor`}
        apiHost={`https://donedone.${env}.credlock.net`}
        country={operator.name.toUpperCase()}
        token={token}
        title='Recent Alerts'
      />
    </div>
  )
}

const MonitoringWrapper = ({ permission, history }) => {
  return (
    <div className='monitoring-wrapper'>
      <Monitoring />
    </div>
  )
}

export default MonitoringWrapper
