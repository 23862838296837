import React from 'react'
import Icon from 'components/Icons'

const PaymentType = ({ paymentMethod }) => {
  if (!paymentMethod) return null

  // A hack introducted to cope with the fact that type is not persisted in biling-service
  // billing-service model PaymentMethod defaults type to 'credit_card', and this is what we will get here
  if (paymentMethod.provider === 'slimpay') {
    paymentMethod = { ...paymentMethod, type: 'sepa' }
  }

  let brand

  if (paymentMethod.type === 'credit_card') {
    brand = paymentMethod.brand
  } else if (paymentMethod.type === 'sepa') {
    brand = 'SEPA'
  } else if (paymentMethod.type === 'invoice') {
    brand = 'Invoice'
  } else {
    brand = 'Unknown'
  }

  const type = brand.toLowerCase()

  const style = {
    fontSize: '20px',
    marginBottom: '-5px',
    marginRight: '5px'
  }

  return (
    <span>
      <Icon type={type} style={style} /> {brand}
    </span>
  )
}

export default PaymentType
