import React, { useState, useEffect } from 'react'
import Table, {
  defaultFilterMethod, Filters
} from 'components/Table'
import PaidPassAPI from 'services/api/paid-passes'
import { ActionButton } from 'components/Buttons'
import { timeToLocale } from '../../components/Helpers'

const PaidPassesList = ({ distributorId, active, operator }) => {
  const [paidPasses, setPaidPasses] = useState(null)
  const [filtered, setFiltered] = useState([])
  const [columns, setColumns] = useState(columnDefinitions(operator))
  const [filterable, setFilterable] = useState(false)

  const fetchPaidPasses = () => {
    PaidPassAPI.paidPasses.search(
      operator.id,
      { distributor_id: distributorId },
      result => setPaidPasses(result)
    )
  }

  useEffect(fetchPaidPasses, [operator, distributorId])

  if (!paidPasses) return null
  if (!active) return null
  if (paidPasses.data.some(p => !p.pin_code)) {
    setTimeout(() => {
      fetchPaidPasses()
    }, 1500)
  }

  const onChange = (e) => {
    setFiltered([{ id: 'pin_code', value: e.target.value }])
  }

  return (
    <>
      <PaidPassFilters
        {...{ onChange, columns, setColumns, distributorId, setFilterable }}
      />

      <div className='content-box'>
        <Table
          sortable
          defaultSorted={[{ id: 'created_at', desc: true }]}
          columns={columns}
          data={paidPasses.data}
          defaultFilterMethod={defaultFilterMethod}
          filterable={filterable}
          filtered={filtered}
        />
      </div>
    </>
  )
}

const pinText = (val) => {
  return (val || 'pending')
}

const Download = ({ distributorId }) => {
  const download = (e) => {
    e.stopPropagation()
    console.log('downloading')
    PaidPassAPI.paidPasses.download(
      { distributor_id: distributorId },
      result => window.location.href = result.url,
      error => console.error)
  }

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '30px'
    }}
    >
      <ActionButton
        onClick={download}
        icon='report white'
        txt='Download .xls'
      />
    </div>
  )
}

const columnDefinitions = (operator) => {
  return [
    {
      Header: 'Created',
      accessor: 'created_at',
      Cell: ({ value }) => timeToLocale(value, operator.timezone),
      show: true,
      filterable: false
    },
    {
      Header: 'Valid from',
      accessor: 'valid_from',
      Cell: ({ value }) => timeToLocale(value, operator.timezone),
      show: true,
      filterable: false
    },
    {
      Header: 'Valid to',
      accessor: 'valid_to',
      Cell: ({ value }) => timeToLocale(value, operator.timezone),
      show: true,
      filterable: false
    },
    {
      Header: 'Used at',
      accessor: 'used_at',
      Cell: ({ value }) => timeToLocale(value, operator.timezone, null),
      show: true,
      filterable: false
    },
    {
      Header: 'Pin',
      accessor: 'pin_code',
      Cell: props => pinText(props.original.pin_code),
      show: true
    },
    {
      Header: 'Lifetime',
      accessor: 'lifetime',
      Cell: ({ value }) => {
        const tmp = []
        for (const [k, v] of Object.entries(value)) {
          tmp.push(`${v} ${k}`)
        }

        return tmp.join('')
      },
      show: true,
      filterable: false
    }
  ]
}

const PaidPassFilters = ({ onChange, columns, setColumns, distributorId, setFilterable }) => {
  return (
    <Filters {...{ columns, setColumns }} toggleFiltered={setFilterable}>
      <div style={{
        display: 'grid',
        gridTemplateColumns: '3fr 1fr',
        gridColumnGap: '20px'
      }}
      >
        <div>
          <h3>Search by Pin Code</h3>
          <form onChange={onChange}>
            <input type='text' />
          </form>
        </div>
        <Download distributorId={distributorId} />
      </div>
    </Filters>
  )
}

export default PaidPassesList
