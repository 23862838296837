import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const BreadCrumb = ({ path, state, text }) => {
  return (
    <Link to={{ pathname: path, state: state }}>
      {text}
    </Link>
  )
}

BreadCrumb.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  state: PropTypes.object
}

export default BreadCrumb
