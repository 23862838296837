import React from 'react'
import Icon from '../../../components/Icons'

const Error = ({ error }) => {
  if (!error) return null

  return (
    <div className='error' style={{ marginTop: '20px' }}>
      <Icon type='error' color='white' />
      {error}
    </div>
  )
}

export default Error
